@import "@laborhack/base-styles/lib/variables";

.wrapper {
  margin: $margin-lg $margin-normal;
  display: grid;
  grid-template-columns: 1fr min(20%, 360px);
}

.actions {
  display: flex;
  justify-content: flex-end;
  margin-bottom: $margin-normal;
  & > * {
    margin-left: $margin-normal;
  }
}

.labels {
  display: flex;
  margin-bottom: $margin-normal;

  & > * {
    margin-left: $margin-normal;
  }
}
