.o-category-section {
  margin: 24px 0;
}

.o-subcategory__modal {
  margin-bottom: 18px;
}
.o-category__form-img-details {
  img {
    width: 95%;
  }
}
