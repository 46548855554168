@import '@laborhack/base-styles/lib/variables.scss';

.wrapper {
  margin: $margin-lg;

  h3 {
    font-weight: bold;
    color: $dark-blue;
  }
}

.list {
  padding: $grid-unit;
  margin: $margin-normal 0;
  max-height: $grid-unit * 100;
  overflow-y: auto;
}

.actions {
  @include flexbox();
  justify-content: end;
}