$primary-color: #009fe3;
$dark-blue: #003952;
$shadow-blue: #f5f9fa;
$backgroundColor: #d8eaf1;

$activeSideNavBgColor: #05acf3;

$backgroundTextColor: #7c95a0;

@mixin flexbox() {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

@mixin flex($values) {
  -webkit-box-flex: $values;
  -moz-box-flex: $values;
  -webkit-flex: $values;
  -ms-flex: $values;
  flex: $values;
}

@mixin order($val) {
  -webkit-box-ordinal-group: $val;
  -moz-box-ordinal-group: $val;
  -ms-flex-order: $val;
  -webkit-order: $val;
  order: $val;
}

.o-refundCreate__container {
  @include flexbox();
  flex-wrap: wrap;

  h3 {
    width: 100%;
    color: $dark-blue;
    font-size: 16px;
    font-weight: 700;
  }

  .o-refundCreate__section {
    margin: 10px 0;

    .m-refundCreate__clientDetailsRadio {
      @include flexbox();
      align-items: center;
      margin: 0 0 10px 0;

      input {
        margin: 0 10px 0 0;
      }

      label {
        color: $dark-blue;
        font-weight: 600;
      }
    }
  }

  .m-refundCreate__actions {
    @include flexbox();
    width: 100%;
    align-items: center;
    justify-content: flex-end;

    .a-refundCreate__actionButton {
      border: none;
      border-radius: 3px;
      padding: 10px 20px;
      font-weight: 600;
      color: #fff;
      cursor: pointer;
      margin-left: 10px;

      &.-negative {
        background-color: red;
      }

      &.-positive {
        background-color: #56bd66;
      }

      &:disabled {
        background-color: #7c95a0;
        cursor: not-allowed;
      }
    }
  }
}

.o-refundAssignment__container {
  @include flexbox();
  flex-wrap: wrap;
  padding: 0 20px;
  .m-refundAssignment__header {
    @include flexbox();
    align-items: center;
    width: 100%;
    cursor: pointer;
    h4 {
      width: 100%;
      color: $dark-blue;
      font-size: 14px;
      font-weight: 700;
    }

    i {
      margin-right: 20px;
      line-height: 14px;
    }
  }

  .m-refundAssignment__table {
    width: 100%;

    .a-refundAssignment__tableText {
      font-size: 13px;
      color: $dark-blue;
      font-weight: 600;
    }

    .m-refundAssignment__tableInputContainer {
      @include flexbox();

      .a-refundAssignment__tableInput {
        height: 36px;
        padding: 0 0 0 10px;
        border-radius: 3px;
        border: 1px solid $backgroundTextColor;
        font-size: 13px;
        color: $dark-blue;
        font-weight: 600;
      }

      .a-refundAssignment__tableButton {
        margin: 0 0 0 10px;
        cursor: pointer;
        background-color: $dark-blue;
        color: $shadow-blue;
        border: none;
        border-radius: 3px;
        padding: 5px 10px;
        font-weight: 600;

        &:disabled {
          background-color: $backgroundTextColor;
          cursor: not-allowed;
        }
      }
    }
  }
}

.o-refundClientAccountForm__container {
  @include flexbox();
  align-items: center;
  margin: 0 0 0 25px;

  .m-refundClientAccountForm__column {
    @include flexbox();
    width: 300px;
    flex-wrap: wrap;
    margin: 0 20px;
    .a-refundClientAccountForm__dropdown {
      border: 1px solid $backgroundTextColor !important;
      color: $dark-blue;
      font-weight: 600;
    }

    .m-refundClientAccountForm__accountInformation {
      @include flexbox();
      align-items: center;
      flex-wrap: nowrap;
      margin: 0 0 0 20px;
      .a-refundClientAccountForm__text {
        margin: 0 10px;
        font-weight: 600;
        color: $dark-blue;
        &.-italics {
          font-style: italic;
        }

        &.-disabled {
          color: #7c95a0;
        }
      }

      i {
        line-height: 16px;
      }
    }
  }
}

.o-refundCreate__submitModalContainer {
  font-family: "Poppins" !important;
  @include flexbox();
  flex-wrap: wrap;
  width: 100%;
  padding: 20px;

  h4,
  h5 {
    font-family: "Poppins" !important;
  }

  .a-refundCreate__submitModalHeader {
    width: 100%;
    font-weight: 700;
    font-size: 16px;
    color: $dark-blue;
  }

  .a-refundCreate__submitModalSubHeader {
    width: 100%;
    font-weight: 700;
    font-size: 14px;
    color: $dark-blue;
    margin-top: 20px;
  }

  .m-refundCreate__submitModalForm {
    width: 100%;
  }

  .m-refundCreate__submitModalActions {
    @include flexbox();
    margin: 20px 0 0 0;
    width: 100%;
    justify-content: flex-end;

    .a-refundCreate__submitModalButton {
      color: #fff;
      background-color: #56bd66;
      padding: 10px 20px;
      border: none;
      border-radius: 3px;
      font-weight: 600;
      cursor: pointer;

      &:disabled {
        background-color: $backgroundTextColor;
      }
    }
  }
}

.o-refundInvoiceDetails__wrapper {
  .m-refundInvoiceDetails__information {
    margin: 0 0 10px 0;
    .a-refundInvoiceDetails__informationHeader {
      color: $backgroundTextColor;
      margin: 0;
      font-size: 12px;
      font-weight: 600;
    }

    .a-refundInvoiceDetails__informationText {
      color: $dark-blue;
      margin: 0;
      font-size: 18px;
      font-weight: 700;

      &.-large {
        font-size: 24px;
      }
    }
  }
}
