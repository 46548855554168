@import '@laborhack/base-styles/lib/variables.scss';

.list {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: $grid-unit * 2;
  margin-bottom: $margin-normal;
}

.addedRecruitmentItem {
  display: grid;
  grid-template-columns: 1fr 48px;
  padding: $padding-normal;
  border: 1px solid $success-color;
  border-radius: 0.5rem;

  .pro {
    display: flex;
    margin: $margin-sm 0;
    h4 {
      font-size: $font-xxl;
      font-weight: bold;
      margin: 0;
      color: $dark-blue;
    }

    .proDetails {
      margin-left: $margin-sm;
      p {
        margin: 0;
        font-size: $font-sm;
        color: $backgroundTextColor;
      }
    }
  }

  p {
    margin: $margin-xs 0;
    color: $backgroundTextColor;
    // font-weight: 600;
  }

  .actions {
    justify-self: end;

    i {
      cursor: pointer;
    }
  }
}
