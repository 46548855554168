@import "../variables.scss";

.wrapper {
  border-radius: $grid-unit;
  box-shadow: 0 $grid-unit $grid-unit * 2 $island-shadow;
  background-color: white;
  padding: $padding-sm $padding-normal;

  .header {
    margin: 0 0 $margin-normal 0;
    &.deep {
      margin: 0 0 $margin-lg 0;
    }

    .headerContainer {
      @include flexbox();
      justify-content: space-between;
      align-items: center;

      h2 {
        text-transform: capitalize;
        font-size: $font-lg;
        line-height: 27px;
        font-weight: bold;
        margin-bottom: $margin-sm;
      }

      .headerActions {
        @include flexbox();
        justify-content: flex-end;
        align-items: center;

        & > * {
          margin: 0 0 0 $margin-normal;
        }

        .customButton {
          margin: 0 $margin-sm 0 0;
          padding: $padding-xs $padding-normal;
          background-color: $dark-blue;
          border: none;
          border-radius: $grid-unit;
          color: white;
          font-weight: 600;
          cursor: pointer;

          &:disabled {
            background-color: $backgroundTextColor;
            cursor: not-allowed;
          }

          &:focus {
            outline: none;
          }
        }
      }
    }

    p {
      color: $backgroundTextColor;
      margin: 0;
    }
  }
}
