@import '~@laborhack/base-styles/lib/variables';

.information {
  max-width: $grid-unit * 75;
  @include flexbox();
  justify-content: flex-start;
  flex-wrap: wrap;
  margin: $margin-lg 0;

  .informationItem {
    width: 100%;
    margin: $margin-normal 0 0 0;
    p {
      margin: $margin-sm 0;
      font-size: $font-sm;
      color: $backgroundTextColor;
    }
  }
}

.header {
  color: $dark-blue;
  font-size: $font-normal;
  font-weight: 600;
  margin: 0 0 $margin-normal 0;
}

.actions {
  @include flexbox();
  justify-content: flex-end;
  margin: $margin-lg 0 0 0;
}
