$primary-color: #009fe3;
$dark-blue: #003952;
$shadow-blue: #f5f9fa;
$backgroundColor: #d8eaf1;
$accent: #ed6e5c;

$activeSideNavBgColor: #05acf3;

$backgroundTextColor: #7c95a0;

@mixin flexbox() {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

@mixin flex($values) {
  -webkit-box-flex: $values;
  -moz-box-flex: $values;
  -webkit-flex: $values;
  -ms-flex: $values;
  flex: $values;
}

@mixin order($val) {
  -webkit-box-ordinal-group: $val;
  -moz-box-ordinal-group: $val;
  -ms-flex-order: $val;
  -webkit-order: $val;
  order: $val;
}

.icon {
  line-height: 1;
}

.confirmBoxButton {
  margin: 0 0 0 8px;
}

.o-orderDetail__editInvoiceModal.ui.fullscreen.modal {
  left: auto !important;
}

.m-orderTable__actions {
  width: 100%;
  @include flexbox();
  justify-content: space-between;
  .a-orderTable__button {
    color: $primary-color;
    text-align: center;
    font-weight: 600;

    &.-negative {
      color: red;
    }

    &:hover {
      text-decoration: underline;
    }
  }
}

.o-orderDetail__tableWrapper {
  .m-orderDetail__tableHeader {
    @include flexbox();
    width: 100%;
    flex-wrap: nowrap;
    align-items: center;
    margin: 30px 0 20px 0;
    h3 {
      font-size: 18px;
      width: fit-content;
      color: $dark-blue;
      font-weight: 600;
      margin: 0 10px 0 0;

      span.a-orderDetail__assignmentsLeft {
        &.-issue {
          color: red;
        }
      }
    }

    i {
      color: $dark-blue;
      line-height: 1;
      cursor: pointer;
    }
  }
  .o-orderDetail__table {
    .a-orderDetail__tableText {
      color: $dark-blue;
      font-weight: 600;

      &.-inactive {
        font-weight: 400;
        color: $backgroundTextColor;
        font-style: italic;
      }
    }

    .a-orderDetail__tableButton {
      background-color: $dark-blue;
      border: none;
      border-radius: 3px;
      color: #fff;
      font-weight: 600;
      padding: 5px 10px;
      cursor: pointer;

      &:disabled {
        background-color: #7c95a070;
        cursor: not-allowed;
      }
    }
  }
}

.o-orderDetail__nestedTable {
  background-color: #009fe31f;
  .a-orderDetail__nestedTableText {
    color: $dark-blue;
    font-weight: 500;

    &.-inactive {
      font-weight: 400;
      color: $backgroundTextColor;
      font-style: italic;
    }
  }

  .a-orderDetail__link {
    font-weight: 700;
    text-transform: capitalize;
    text-decoration: underline;
    text-decoration-thickness: 3px;
    background: none;
    border: none;
    color: $primary-color;
    cursor: pointer;
    &:focus {
      outline: none;
    }
  }
}

.o-orderDetail__information {
  @include flexbox();
  flex-wrap: wrap;
  margin-bottom: 30px;
  .m-orderDetail__informationWrapper {
    width: 50%;
    padding-left: 40px;
    h4 {
      text-transform: capitalize;
      margin-bottom: 20px;
      font-size: 18px;
      font-weight: 600;
      color: $backgroundTextColor;
    }
    .m-orderDetail__informationField {
      @include flexbox();
      flex-wrap: wrap;
      margin-left: 20px;
      margin-bottom: 10px;

      .m-orderDetail__informationFieldSection {
        @include flexbox();
        flex-wrap: wrap;

        .iconTitle {
          width: 100%;
          margin: 0 0 8px 0;
          @include flexbox();
          flex-wrap: nowrap;
          align-items: center;
          justify-content: flex-start;

          h5 {
            margin: 0 8px 0 0;
            width: fit-content;
          }

          i {
            line-height: 1em;
          }
        }

        .a-orderDetail__informationFieldButton {
          margin: 0 10px 0 0;
        }
      }

      h5 {
        width: 100%;
        text-transform: capitalize;
        color: $backgroundTextColor;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 0.1em;
      }

      p {
        color: $dark-blue;
        font-size: 14px;
        font-weight: 600;
        &.-large {
          font-size: 24px;
          line-height: 36px;
        }
        &.-capitalize {
          text-transform: capitalize;
        }
      }
    }
  }
}

.o-orderDetails__jobsWrapper {
  h4 {
    color: $dark-blue;
    font-size: 18px;
    font-weight: bold !important;
    text-transform: capitalize;
  }
}

.ant-steps-item-title {
  font-size: 12px !important;
  font-weight: 600px !important;
}

.ant-steps-item-active {
  .ant-steps-item-title {
    font-size: 14px !important;
    font-weight: 700 !important;
  }
}

.o-orderDetails__jobsWrapper {
  h4 {
    color: $dark-blue;
    font-size: 18px;
    font-weight: bold !important;
    text-transform: capitalize;
  }
}

.o-jobDetails__manageWrapper {
  padding-left: 40px;
  h4 {
    color: $dark-blue;
    font-size: 18px;
    font-weight: bold !important;
    text-transform: capitalize;
  }

  .m-jobManage__menu {
    font-family: 'Poppins' !important;
    .item {
      color: $backgroundTextColor !important;
      font-weight: 400 !important;
    }
    .active {
      color: $dark-blue !important;
      font-weight: 600 !important;
    }
  }

  .o-manage__tradesmanWrapper {
    h5 {
      text-transform: capitalize;
      color: $backgroundTextColor;
      font-weight: 500;
      margin: 20px 0 20px 0;
      span {
        color: $dark-blue;
        font-weight: 600;
      }
    }
  }

  .o-manage__jobStatusWrapper {
    padding: 20px 0;
  }
}

.pointer {
  cursor: pointer;
}

.m-assignmentCardList__wrapper {
  @include flexbox();
  flex-wrap: wrap;
}

.o-assignmentCard__wrapper {
  @include flexbox();
  flex-direction: column;
  justify-content: space-between;
  width: 300px;
  height: 360px;
  background-color: $dark-blue;
  border-radius: 8px;
  box-shadow: 0px 4px 12px 6px rgba(158, 203, 214, 0.31);
  margin: 0 20px 20px 0;

  .o-assignmentCard__head {
    @include flexbox();
    justify-content: space-between;

    .m-assignmentCard__headDateWrapper {
      @include flexbox();
      justify-content: center;
      align-items: center;
      border-radius: 8px 0 0 0;
      background-color: #fff;
      width: 72px;
      height: 60px;
      .a-assignmentCard__headDateContent {
        text-align: center;
        h6,
        h5 {
          margin: 0;
          padding: 0;
          color: $dark-blue;
          letter-spacing: 0.1em;
        }

        h6 {
          text-transform: uppercase;
          font-size: 14px;
          font-weight: 600;
        }

        h5 {
          font-size: 18px;
          font-weight: 700;
        }
      }
    }
    .m-assignmentCard__headTasks {
      padding: 10px 16px 0 0;
      text-align: right;
      h5,
      h6 {
        color: #fff;
        margin: 0;
        padding: 0;
      }

      h6 {
        font-weight: 500;
        font-size: 14px;
      }

      h5 {
        font-size: 24px;
        font-weight: 700;
      }
    }
  }

  .m-assignmentCard__body {
    color: #fff;
    margin-left: 20px;
    h3 {
      color: #fff;
      font-weight: 700;
    }
    .a-assignmentCard__bodySub {
      @include flexbox();
      align-items: center;
      height: 36px;
      h4 {
        text-transform: capitalize;
        color: #fff;
      }
      i {
        line-height: 1em;
        margin: 0 10px 0 0;
        font-weight: 400;
      }
    }
  }

  .m-assignmentCard__actions {
    @include flexbox();
    justify-content: flex-end;
    margin: 0 20px 20px 0;
    color: #fff;

    i.a-assignmentCard__action {
      font-size: 18px;
      line-height: 18px;
      color: #fff;
      cursor: pointer;
      margin-left: 10px;

      &.-red {
        color: #dd5954;
      }
    }
  }
}

.o-assignmentCard__createWrapper {
  cursor: pointer;
  @include flexbox();
  justify-content: center;
  align-content: center;
  background-color: #fff;
  border: 5px solid #003952;
  .m-assignmentCard__body {
    text-align: center;
    color: $dark-blue;
    margin: 0;
    h4 {
      font-size: 18px;
      font-weight: 700;
    }

    i {
      font-size: 36px;
    }
  }
}

.o-assignmentForm__selectedOverviewWrapper {
  @include flexbox();
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: $dark-blue;
  border-radius: 5px;
  box-shadow: 0px 4px 12px rgba(158, 203, 214, 0.31);
  width: 100%;
  height: 640px;
  padding: 24px;

  .o-assignmentForm__selectedOverviewItems {
    @include flexbox();
    width: 100%;
    flex-wrap: wrap;

    h3 {
      text-transform: capitalize;
      width: 100%;
      margin: 0;
      font-size: 18px;
      font-weight: 700;
      color: #fff;
      margin: 0 0 20px 0;
    }
    .m-assignmentForm__selectedOverviewItem {
      @include flexbox();
      width: 100%;
      justify-content: space-between;
      align-items: center;
      color: #fff;
      height: 36px;

      .a-ssignmentForm__selectedOverviewItemCount {
        font-weight: 700;
        text-transform: lowercase;
      }

      p {
        text-transform: capitalize;
        font-size: 600;
        color: #fff;
        margin: 0;
        line-height: 21px;
      }

      i {
        line-height: 21px;
        cursor: pointer;
      }
    }
  }

  .o-assignment__sidebarSection {
    @include flexbox();
    width: 100%;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    h4 {
      align-self: flex-start;
      font-size: 14px;
      color: white;
      font-weight: bold;
    }
  }

  .o-assignmentForm__selectedOverviewActions {
    @include flexbox();
    width: 100%;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    & > * {
      margin: 16px 0 0 0;
    }

    .a-assignmentForm__selectedOverviewButton {
      text-transform: capitalize;
      font-family: 'Poppins', sans-serif;
      background-color: $primary-color;
      color: #fff;
      font-weight: 600;
      height: 48px;

      &.-basic {
        background-color: #fff;
        color: $dark-blue;
        &:hover {
          background-color: #fff;
          color: $dark-blue;
        }
      }

      &.-negative {
        background-color: #dd5954;
        color: #fff;
        &:hover {
          background-color: #dd5954;
          color: #fff;
        }
      }

      &.-positive {
        background-color: #56bd66;
        color: #fff;
        &:hover {
          background-color: #56bd66;
          color: #fff;
        }
      }

      &:hover {
        background-color: $primary-color;
        color: #fff;
      }
    }
  }
}

.o-assignmentForm__selectionWrapper {
  width: 100%;
  padding: 0 42px 42px 42px;
  .m-assignmentForm__selectionHeader {
    @include flexbox();
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    height: 60px;

    h4 {
      text-transform: capitalize;
      color: $dark-blue;
      font-size: 14px;
      font-weight: 700;
    }

    .a-assignmentForm__selectionHeaderDropdown {
      font-family: 'Poppins';
      background-color: $primary-color;
      color: #fff;
      font-size: 14px !important;
      font-weight: 600 !important;
      &.-secondary {
        background-color: $dark-blue;
        &:hover {
          background-color: #000000 !important;
          color: #fff !important;
        }
        &:active {
          background-color: #000000;
          color: #fff;
        }
        &:focus {
          background-color: $dark-blue;
          color: #fff;
        }
      }
      &:hover {
        background-color: $primary-color !important;
        color: #fff !important;
      }
      &:active {
        background-color: $primary-color;
        color: #fff;
      }
      &:focus {
        background-color: $primary-color;
        color: #fff;
      }
    }
  }

  .o-assignmentForm__selectionItems {
    width: 100%;
    padding: 20px 0;
    height: 200px;
    overflow-y: auto;
  }
}

.o-jobSelectedItem__wrapper {
  @include flexbox();
  width: 100%;
  padding: 10px 20px;
  margin: 0 0 10px 0;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;
  box-shadow: 0px 2px 8px 2px rgba(158, 203, 214, 0.6);
  cursor: pointer;

  &:hover {
    background-color: $dark-blue;
    box-shadow: 0px 2px 16px 2px rgba(158, 203, 214, 0.6);

    .m-jobSelectedItem__details {
      h4 {
        color: #fff;
      }

      p {
        color: $shadow-blue;
      }
    }

    h5 {
      color: #fff;
    }

    i {
      color: #fff;
    }
  }

  .m-jobSelectedItem__details {
    h4 {
      color: $dark-blue;
      text-transform: capitalize;
      font-weight: 700;
    }

    p {
      color: $backgroundTextColor;
      font-weight: 500;
      text-transform: capitalize;
    }
  }

  h5 {
    text-transform: capitalize;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    margin: 0;
  }

  i {
    margin: 0;
    padding: 0;
    font-size: 14px;
    line-height: 14px;
  }
}

.o-tradesmanSelectedItem__wrapper {
  display: grid;
  grid-template-columns: 35% 20% 20% 20% 5%;
  width: 100%;
  padding: 10px 20px;
  margin: 0 0 10px 0;
  align-items: center;
  border-radius: 5px;
  box-shadow: 0px 2px 8px 2px rgba(158, 203, 214, 0.6);
  cursor: pointer;

  &:hover {
    background-color: $dark-blue;
    box-shadow: 0px 2px 16px 2px rgba(158, 203, 214, 0.6);

    .m-tradesmanSelectedItem__details {
      h4 {
        color: #fff;
      }

      p {
        color: $shadow-blue;
      }
    }

    h5 {
      color: #fff;
    }

    i {
      color: #fff;
    }
  }

  &.-selected {
    background-color: #56bd66;
    box-shadow: 0px 2px 16px 2px rgba(158, 203, 214, 0.6);

    .m-tradesmanSelectedItem__details {
      h4 {
        color: #fff;
      }

      p {
        color: $shadow-blue;
      }
    }

    h5 {
      color: #fff;
    }

    i {
      color: #fff;
    }
  }

  .m-tradesmanSelectedItem__details {
    h4 {
      color: $dark-blue;
      text-transform: capitalize;
      font-weight: 700;
    }

    p {
      color: $backgroundTextColor;
      font-weight: 500;
      text-transform: capitalize;
    }
  }

  h5 {
    color: $dark-blue;
    text-transform: capitalize;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    margin: 0;
  }

  i {
    color: $dark-blue;
    margin: 0;
    padding: 0;
    font-size: 14px;
    line-height: 14px;
  }
}

.m-assignmentForm__selectionDate {
  position: relative;
  width: 100%;

  label {
    font-size: 14px;
    font-weight: 600;
    color: #fff;
    padding: 10px 0;
  }

  .nice-dates-popover {
    left: 0;
    right: 0;
  }

  .a-assignmentForm__selectionDateInput {
    height: 42px;
    width: 100%;
    border: 1px solid $backgroundTextColor;
    border-radius: 3px;
    padding-left: 10px;
    font-size: 14px;
    font-weight: 600;
    &::placeholder {
      font-weight: 600;
      text-transform: uppercase;
    }
  }
}

.o-assignmentForm__tableControls {
  width: 100%;
  input {
    height: 42px !important;
    background-color: $backgroundColor !important;
    border: none !important;
    font-family: 'Poppins' !important;
    font-size: 12px !important;
    font-weight: 500 !important;
    &::placeholder {
      color: #507a8c !important;
    }
  }

  .m-assignmentForm__dropdown {
    font-family: 'Poppins';
    background-color: $primary-color;
    color: #fff;
    font-size: 12px !important;
    font-weight: 400 !important;
    &.-secondary {
      background-color: $dark-blue;
      &:hover {
        background-color: #000000 !important;
        color: #fff !important;
      }
      &:active {
        background-color: #000000;
        color: #fff;
      }
      &:focus {
        background-color: $dark-blue;
        color: #fff;
      }
    }
    &:hover {
      background-color: $primary-color !important;
      color: #fff !important;
    }
    &:active {
      background-color: $primary-color;
      color: #fff;
    }
    &:focus {
      background-color: $primary-color;
      color: #fff;
    }
  }
}

.a-order__button {
  font-family: 'Poppins', sans-serif !important;
  background-color: $dark-blue !important;
  color: #fff !important;
  margin-bottom: 20px !important;
  &.-secondary {
    background-color: $shadow-blue;
    color: $dark-blue;
  }
  &.-negative {
    background-color: red;
    color: #fff;
  }
}

.m-viewAssignment__paymentModal {
  h4 {
    text-transform: capitalize;
    color: $dark-blue;
    font-family: 'Poppins' !important;
    font-size: 18px;
    font-weight: 700;

    i {
      color: #56bd66;
    }
  }

  p {
    font-family: 'Poppins' !important;
    font-size: 14px;
  }
}

.o-orderDetail__editInvoiceWrapper {
  .m-orderDetail__editInvoiceAction {
    @include flexbox();
    width: 100%;
    justify-content: flex-end;
    margin: 20px 0 0 0;

    button {
      background-color: $dark-blue;
      border: none;
      border-radius: 3px;
      font-family: 'Poppins', sans-serif;
      font-weight: 600;
      color: white;
      cursor: pointer;
      padding: 0 20px;
      height: 42px;

      &:focus {
        outline: none;
      }
    }
  }

  h4 {
    text-transform: capitalize;
    color: $dark-blue;
    font-family: 'Poppins' !important;
    font-size: 18px;
    font-weight: 700;

    i {
      color: #56bd66;
    }
  }

  table {
    width: 100%;
    .o-invoiceSection__header {
      border: none;
      td {
        text-align: center !important;

        .m-invoiceSection__divider {
          @include flexbox();
          flex-wrap: nowrap;
          align-items: center;
          justify-content: space-around;
          width: 100%;

          .a-invoiceSection__dividerLine {
            width: 30%;
            height: 0px;
            border-top: 2px dashed $backgroundTextColor;
          }

          .a-invoiceSection__dividerText {
            font-style: italic;
            margin: 0;
            width: fit-content;
          }
        }
      }
    }

    th,
    td {
      font-family: 'Poppins', sans-serif;
      font-size: 1em;
      text-align: left;

      &:first-child {
        width: 30%;
      }

      &:last-child {
        padding-left: 10px;
        width: 10%;
        text-align: right;
      }
    }

    th {
      font-weight: 600;
      color: $backgroundTextColor;
      tr {
        border: none !important;
      }
    }

    td {
      .m-editInvoice__amountFieldContainer {
        @include flexbox();
        justify-content: flex-end;
        align-items: center;
        flex-wrap: wrap;
        width: 100%;

        input.a-editInvoice__amountField {
          width: 100px;
          padding: 0 0 0 10px;
          margin: 0 0 10px 0;
          flex: 1;
          height: 42px;
          border-radius: 2px;
          border: 2px solid #428aa8;

          &:focus {
            outline: none;
          }
        }
      }

      color: $dark-blue;
      .m-editInvoice__descriptionWrapper {
        @include flexbox();
        justify-content: center;
        flex-wrap: wrap;
        width: 100%;

        .m-editInvoice__descriptionFieldContainer,
        .m-editInvoice__laborField {
          @include flexbox();
          justify-content: space-between;
          align-items: center;
          flex-wrap: wrap;
          width: 100%;

          input.a-editInvoice__descriptionField {
            padding: 0 0 0 10px;
            margin: 0 10px 10px 0;
            flex: 1;
            height: 42px;
            border-radius: 2px;
            border: 2px solid #428aa8;

            &:focus {
              outline: none;
            }
          }

          button.a-editInvoice__descriptionFieldButton {
            background-color: transparent;
            border: none;
            font-family: 'Poppins', sans-serif;
            font-weight: 600;
            color: $dark-blue;
            cursor: pointer;
            margin: 0 0 10px 0;

            &:focus {
              outline: none;
            }

            i {
              height: 1.2em;
              font-size: 1.2em;
              line-height: 1.2em;
            }
          }
        }

        button.a-editInvoice__addDescriptionButton {
          width: fit-content;
          text-align: left;
          background-color: transparent;
          border: none;
          font-family: 'Poppins', sans-serif;
          font-size: 1em;
          font-weight: 600;
          color: $dark-blue;
          cursor: pointer;

          &:focus {
            outline: none;
          }
        }
      }

      padding: 20px 0;
      &:first-child {
        vertical-align: top;
        text-transform: capitalize;
        font-weight: 500;
      }

      &:last-child {
        vertical-align: top;
      }
    }

    tr {
      padding: 10px 0;
      border-top: 1px solid $dark-blue;
      background-color: transparent;
    }
  }

  p {
    font-family: 'Poppins' !important;
    font-size: 14px;
  }
}

.o-viewAssignment__steps {
  width: 100%;
  margin-bottom: 20px;
  padding: 0 20px;
}

.o-viewAssignment__wrapper {
  @include flexbox();
  width: 100%;
  padding-left: 20px;
  min-height: 540px;
  flex-direction: column;
  .o-viewAssignment__details {
    margin-bottom: 50px;
    .m-viewAssignment__detailItem {
      margin-bottom: 20px;
      h5 {
        margin: 0;
        padding: 0;
        text-transform: capitalize;
        font-size: 14px;
        color: $backgroundTextColor;
        font-weight: 500;
      }
      p {
        margin: 0;
        padding: 0;
        text-transform: capitalize;
        font-size: 14px;
        color: $dark-blue;
        font-weight: 700;
      }
    }
  }

  .o-viewAssignment__costBreakdown {
    .m-viewAssignment__costBreakdownHeader {
      @include flexbox();
      width: 100%;
      justify-content: space-between;
      align-items: center;
      h5 {
        margin: 0;
        padding: 0;
        text-transform: capitalize;
        font-size: 14px;
        color: $backgroundTextColor;
        font-weight: 500;
      }

      button {
        border: none;
        border-radius: 4px;
        text-align: center;
        font-family: 'Poppins', sans-serif;
        padding: 10px 20px;
        height: 42px;
        background-color: $dark-blue;
        vertical-align: middle;
        cursor: pointer;

        p {
          color: white;
          font-weight: 600;
        }

        &:focus {
          outline: none;
        }
      }
    }
    .o-viewAssignment__costBreakdownWindow {
      height: 200px;
      overflow: auto;
      .m-viewAssignment__costBreakdownItem {
        @include flexbox();
        height: 48px;
        justify-content: space-between;
        align-items: center;
        p {
          margin: 0;
          cursor: pointer;
          padding: 0;
          text-transform: capitalize;
          font-size: 14px;
          color: $dark-blue;
          font-weight: 700;
          &:hover {
            color: $primary-color;
          }
          &:last-child {
            cursor: text;
            &:hover {
              color: $dark-blue;
            }
            font-size: 18px;
            text-align: right;
          }
        }
      }
    }
  }

  .o-viewAssignment__finalCost {
    @include flexbox();
    justify-content: flex-end;
    .m-viewAssignment__finalCostDetail {
      text-align: right;
      h5 {
        margin: 0;
        padding: 0;
        text-transform: capitalize;
        font-size: 14px;
        color: $backgroundTextColor;
        font-weight: 500;
      }

      p {
        margin: 0;
        padding: 0;
        text-transform: capitalize;
        font-size: 24px;
        color: #56bd66;
        font-weight: 700;
      }
    }
  }
}

.m-viewAssignment__costBreakdownAmount {
  vertical-align: top;
  h4 {
    font-size: 18px;
    color: $dark-blue;
    font-family: 'Poppins', sans-serif !important;
    font-weight: 700;
  }
}

.m-viewAssignment__costBreakdownName {
  h4 {
    font-size: 16px;
    color: $dark-blue;
    font-family: 'Poppins', sans-serif !important;
    font-weight: 700;
  }

  p {
    color: $dark-blue;
    font-family: 'Poppins', sans-serif !important;
    font-weight: 400;
  }
}

.o-sendInvoice__confirmBoxWrapper {
  @include flexbox();
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  .o-sendInvoice__confirmBoxContainer {
    @include flexbox();
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    padding: 20px 20px 0 20px;
    flex-wrap: wrap;

    h4.prompt {
      font-size: 18px;
      width: 100%;
      font-family: 'Poppins', sans-serif;
      font-weight: 800;
      color: $dark-blue;
    }
    .m-sendInvoice__confirmBoxAction {
      @include flexbox();
      justify-content: flex-end;
      align-items: center;
      width: 100%;
      padding: 10px 0;

      .a-sendInvoice__confirmBoxButton {
        text-align: center;
        outline: none;
        padding: 12px 20px;
        color: $shadow-blue;
        border: none;
        border-radius: 3px;
        margin: 0 0 0 10px;
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
        background-color: $dark-blue;
        cursor: pointer;
        &.-negative {
          background-color: #dd5954;
        }

        &:disabled {
          background-color: #7c95a0;
        }

        &.-positive {
          background-color: #56bd66;
        }
      }
    }
  }
}
.ant-table-selection-column {
  background: #fafafa;
}

.o-selectAvailablePro__wrapper {
  @include flexbox();
  flex-wrap: wrap;
  width: 100%;

  .o-selectAvailablePro__controls {
    @include flexbox();
    width: 100%;
    margin: 20px 0 0 0;
    justify-content: space-between;
    align-items: flex-end;

    .m-selectAvailablePro__controlContainer {
      @include flexbox();
      flex-wrap: wrap;

      .a-selectAvailablePro__inputDropdown {
        background-color: $dark-blue !important;
        color: $shadow-blue !important;
      }

      .a-selectAvailablePro__label {
        font-size: 14px;
        font-weight: 600;
        width: 100%;
        color: $dark-blue;
      }

      .o-selectAvailablePro__datePicker {
        height: 36px;
        width: 150px;

        .react-date-picker__wrapper {
          border-radius: 3px;
          padding: 0 10px;
          border-color: $backgroundTextColor;
          background-color: $dark-blue;
          color: #fff !important;
          button {
            margin: 0 !important;

            &:focus {
              outline: none;
            }
          }

          .react-calender {
            button {
              margin-bottom: 0px !important;
            }
          }
        }
      }
    }
  }

  .a-selectAvailablePro__resultHeader {
    font-weight: 600;
    color: $dark-blue;
    margin: 20px 0 10px 0;
  }

  .o-selectAvailablePro__results {
    margin-top: 20px;
    width: 100%;
    @include flexbox();
    flex-direction: column;
  }

  .o-selectAvailablePro__resultContainer {
    width: 100%;
    flex-grow: 2;
    overflow-y: auto;
    padding: 20px 0;

    &.-small {
      flex-grow: 1;
    }

    .m-selectAvailablePro__resultStatus {
      @include flexbox();
      width: 100%;
      justify-content: center;
    }
  }
}

.o-scheduleSuggestedPro__wrapper {
  margin: 20px 0;
}

.o-suggestedProCard__wrapper {
  width: 100%;
  padding: 10px;
  box-shadow: 0px 2px 8px 2px rgba(158, 203, 214, 0.6);
  border-radius: 3px;
  margin: 0 0 10px 0;

  &.-selected {
    border: 2px solid #56bd66;
  }

  .o-suggestedProCard__overview {
    @include flexbox();
    width: 100%;
    align-items: center;
    justify-content: space-between;

    .m-suggestedProCard__proDetails {
      h5 {
        font-weight: 600;
        margin: 0;
        color: $dark-blue;
      }

      p {
        margin: 0;
        color: $backgroundTextColor;
      }
    }

    .a-suggestedProCard__toggle {
      i {
        color: $dark-blue;
        cursor: pointer;
      }
    }
  }

  .o-suggestedProCard__actions {
    @include flexbox();
    width: 100%;
    justify-content: space-between;
    align-items: flex-end;
    margin: 20px 0 0 0;

    .o-suggestedProCard__selectDate {
      p {
        font-weight: 600;
        color: $dark-blue;
        margin: 0;
      }
    }

    .a-suggestedProCard__button {
      height: 32px;
      padding: 0 20px;
      font-weight: 600;
      color: $shadow-blue;
      background-color: #56bd66;
      border: none;
      border-radius: 3px;
      cursor: pointer;

      &.-selected {
        background-color: #dd5954;
      }
    }
  }
}

.a-suggestedProCard__calenderTile {
  background-color: #56bd66;

  &.-unavailable {
    background-color: #dd5954;
    color: #fff;
    cursor: not-allowed;

    &:hover {
      background-color: #dd5954;
    }
  }
}

// Invoice Styles
.o-orderInvoice__wrapper {
  @include flexbox();
  justify-content: center;
  width: 100%;
  color: $dark-blue;
  font-family: 'Poppins', sans-serif;

  h4,
  h3 {
    font-weight: 600;
  }

  p {
    color: $backgroundTextColor;
  }

  h3,
  h4,
  p {
    font-family: 'Poppins', sans-serif;
    line-height: 1.53;
    margin: 0;
  }

  .o-orderInvoice__container {
    box-shadow: 0px 5px 12px rgba(158, 203, 214, 0.31);
    padding: 40px 20px;
    width: 100%;
    max-width: 720px;

    .header {
      @include flexbox();
      width: 100%;
      justify-content: space-between;
      align-items: flex-start;

      .logo {
        h1 {
          font-family: 'Poppins', sans-serif;
          line-height: 1;
          color: $accent;
          margin: 0;
          font-weight: 800;
        }
      }

      .address {
        article {
          text-align: right;
          p {
            margin: 0;
          }
        }
      }
    }

    .mid-section {
      @media (max-width: 576px) {
        display: none;
      }
      margin-top: 48px;
      @include flexbox();
      width: 100%;
      justify-content: space-between;

      .recipient-details {
        h4 {
          font-size: 14px;
          color: $dark-blue;
        }
      }

      .invoice-details {
        h3 {
          font-weight: 700;
          color: $dark-blue;
          font-size: 24px;
        }
        text-align: right;
        .invoice-field {
          h4 {
            font-weight: 400;
            font-size: 14px;
            color: $dark-blue;
          }

          margin: 10px 0;
        }
      }
    }

    .mid-section-sm {
      width: 100%;
      display: none;
      margin-top: 48px;
      flex-wrap: wrap;
      @media (max-width: 576px) {
        @include flexbox();
      }
    }

    .purchase-overview {
      @include flexbox();
      justify-content: flex-end;
      flex-wrap: wrap;
      margin-top: 72px;
      width: 100%;

      .purchase-table {
        width: 100%;
        table {
          border-radius: 0;
          width: 100%;

          thead {
            tr {
              height: 24px;
            }
          }

          th {
            font-size: 14px !important;
            background-color: $dark-blue;
            font-weight: 600;
            color: #fff;
          }

          td {
            text-transform: capitalize;
            font-weight: 400;
            color: $dark-blue;
            border-collapse: collapse;
            border-spacing: 0px;
            // border-top: 1px solid #f3f9fc;

            &.-strike {
              div {
                .m-invoice__breakdownItem {
                  h4 {
                    text-decoration: line-through;
                    text-decoration-thickness: 2px;
                  }
                }

                p {
                  text-decoration: line-through;
                }
              }
            }

            h3 {
              padding: 20px 0 0 0;
              text-align: left;
              color: $dark-blue;
              font-size: 14px;
            }

            div {
              .m-invoice__breakdownItem {
                @include flexbox();
                align-items: center;
                h4 {
                  font-weight: 500;
                  font-size: 13px;
                  color: $dark-blue;
                }

                .a-invoice__breakdownItemButton {
                  padding: 5px 10px;
                  margin-left: 10px;
                  border: none;
                  border-radius: 3px;
                  background-color: $accent;
                  color: #fff;
                  font-weight: 800;
                  cursor: pointer;

                  &.-undo {
                    background-color: #fff;
                    color: green;
                    border: 1px solid green;
                  }

                  &:focus {
                    outline: none;
                  }
                }
              }
            }

            &:last-child {
              font-weight: 500;
            }
          }

          th,
          td {
            font-size: 13px;
            padding: 5px 10px 5px 10px;
            text-align: left;
            border: none;
            &:last-child {
              vertical-align: top;
              width: 40%;
              text-align: right;
            }
          }
        }
      }

      .purchase-calculation {
        border-top: 2px solid #c5d2d8;
        width: 360px;

        .row {
          @include flexbox();
          padding: 10px 0 0 0;
          width: 100%;
          justify-content: space-between;
          align-items: center;

          h4 {
            width: fit-content;
          }
        }

        @media (max-width: 576px) {
          width: 100%;
        }

        p.total-due {
          font-size: 24px;
          color: $accent;
          font-weight: 800;
        }
      }
    }
  }
}

.m-assignmentPane__actions {
  width: 100%;
  @include flexbox();
  align-items: center;
  justify-content: flex-end;
  margin: 16px 0;
}
