@import '~@laborhack/base-styles/lib/variables.scss';

.assignmentCardTitle {
  width: 100%;
  min-height: $grid-unit * 6;
  margin: $margin-sm 0;
  padding: 0 $padding-normal;
  @include flexbox();
  align-items: center;
  flex-wrap: nowrap;

  p {
    color: $dark-blue;
    font-size: $font-sm;
    margin: 0;
  }

  .toggleWrapper {
    width: 5%;
  }

  .category {
    width: 10%;
  }

  .pro {
    width: 20%;
  }

  .location {
    width: 15%;
  }

  .inspection {
    width: 15%;
  }

  .status {
    width: 10%;
  }

  .selectedPreview {
    flex: 2;
  }
}
