@import '~@laborhack/base-styles/lib/variables';

.heading {
  font-size: $font-lg;
  font-weight: bold;
}

.modalContainer {
  @include flexbox();
  width: 100%;
  margin: $margin-lg 0 0 0;
  align-items: center;
  flex-wrap: nowrap;
}

.saveButton {
  margin: 0 0 0 $margin-normal;
}
