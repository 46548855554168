@import "@laborhack/base-styles/lib/variables";

.item {
  width: 960px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 2fr 3fr 5fr;
  align-items: center;
  margin-bottom: $margin-sm;
}

.breakdown {
  margin: $margin-normal;
}

.section {
  margin: $margin-normal 0;

  h4 {
    color: $dark-blue;
    font-weight: 500;
  }
}

.rate {
  height: $grid-unit * 9;
  width: $grid-unit * 25;
  border-radius: $grid-unit;
  border: 1px solid rgba($color: $dark-blue, $alpha: 0.2);
  padding: $padding-sm;
}

.splitItem {
  margin: $margin-lg;
  color: $dark-blue;

  h3 {
    color: $dark-blue;
    font-weight: bold;
  }
}

.body {
  margin: $margin-normal;

  .prompt {
    margin-top: $margin-normal;
  }
}

.dataEntry {
  margin: $margin-lg 0;
  display: flex;
  flex-direction: column;
  input {
    width: $grid-unit * 50;
    height: $grid-unit * 8;
    border: 1px solid rgba($color: $dark-blue, $alpha: 0.2);
    border-radius: $grid-unit;
    padding: $padding-sm;
    margin-top: $margin-sm;
  }
}

.errors {
  margin-top: $margin-normal;
  color: red;
}

.note {
  max-width: $grid-unit * 100;
}

.actions {
  display: flex;
  justify-content: flex-end;
  margin-top: $margin-lg;
  margin-bottom: $margin-normal;
}
