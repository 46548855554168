@import '@laborhack/base-styles/lib/variables.scss';

.changeContractLabel {
  @include flexbox();
  align-items: center;

  p {
    margin: 0;
    margin-right: $margin-lg;
  }
}

.field {
  margin: $margin-xs 0;
  max-width: 320px;

  label {
    display: block;
    font-weight: 600;
    color: $dark-blue !important;
    margin-bottom: $margin-sm;
  }
}

.input {
  width: 100%;
  div {
    width: 100%;
  }
  input {
    width: 100% !important;
  }
}

.contract {
  display: grid;
  grid-template-columns: 2fr 1fr;
  align-items: flex-start;
  gap: $grid-unit * 2;
}

.setStartDate {
  @include flexbox();
  flex-direction: column;

  .endDate {
    margin-top: $margin-lg;
  }
}

.calendar {
  margin: $margin-normal;
}

.selectedProCard {
  display: grid;
  grid-template-columns:  1fr 24px;
  align-items: center;
  gap: $grid-unit * 4;
  max-width: $grid-unit * 80;
  padding: $padding-normal;
  border: 2px solid $success-color;
  border-radius: $grid-unit * 2;

  i {
    cursor: pointer;
  }
}

.actions {
  width: 100%;
  margin: $margin-normal 0;
  @include flexbox();
  justify-content: end;
}