@import '~@laborhack/base-styles/lib/variables.scss';

.assignmentCard {
  width: 100%;
  min-height: $grid-unit * 15;
  margin: 0 0 $margin-normal 0;
  padding: $padding-sm $padding-normal;
  @include flexbox();
  align-items: center;
  flex-wrap: wrap;
  border-radius: $grid-unit * 2;
  border: 3px solid #ffffff;
  box-shadow: 0px 4px 12px 2px rgba($color: $dark-blue, $alpha: 0.15);
  cursor: pointer;
  background-color: white;

  &.source {
    border-color: $blue;
    box-shadow: 0px 4px 12px 2px rgba($color: $blue, $alpha: 0.15);
  }

  &.target {
    border-color: $success-color;
    box-shadow: 0px 4px 12px 2px rgba($color: $success-color, $alpha: 0.15);
  }

  .summary {
    @include flexbox();
    align-items: center;
    flex-wrap: nowrap;
    width: 100%;
    .column {
      @include flexbox();
      align-items: center;
      height: $grid-unit * 11;

      &.toggleWrapper {
        width: 5%;

        .toggle {
          font-size: $font-lg;
          color: $backgroundTextColor;
          line-height: 1;
          cursor: pointer;
          &:hover {
            color: $dark-blue;
          }
        }
      }

      &.category {
        width: 10%;
      }

      &.pro {
        width: 20%;
      }

      &.location {
        width: 15%;
      }

      &.inspection {
        width: 15%;
        .icon {
          font-size: $font-lg;
          line-height: 1;

          &.success {
            color: $success-color;
          }

          &.fail {
            color: $negative-color;
          }
        }

        p {
          font-style: italic;
          color: $backgroundTextColor;
        }
      }

      &.status {
        width: 10%;
      }

      &.selectedPreview {
        flex: 2;
        align-items: flex-start;
        justify-content: flex-end;
      }

      & > p {
        font-size: $font-normal;
        font-weight: 600;
        color: $dark-blue;
      }
    }
  }

  .details {
    width: 100%;
    margin: $margin-normal 0;
    h6 {
      font-size: $font-normal;
      font-weight: 600;
      color: $dark-blue;
      margin: 0 0 $margin-sm 0;
    }

    p {
      font-size: $font-sm;
      color: $dark-blue;
      margin: 0;
    }
  }
}
