@import "@laborhack/base-styles/lib/variables";

.wrapper {
  margin: $margin-sm $margin-normal;
  display: flex;
  flex-direction: column;
  color: $dark-blue;

  .section {
    margin-top: $margin-normal;

    h3 {
      color: $dark-blue;
      font-weight: bold;
    }
  }
}
