$primary-color: #009fe3;
$dark-blue: #003952;
$shadow-blue: #f5f9fa;
$backgroundColor: #d8eaf1;

$activeSideNavBgColor: #05acf3;

$backgroundTextColor: #7c95a0;

@mixin flexbox() {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

@mixin flex($values) {
  -webkit-box-flex: $values;
  -moz-box-flex: $values;
  -webkit-flex: $values;
  -ms-flex: $values;
  flex: $values;
}

@mixin order($val) {
  -webkit-box-ordinal-group: $val;
  -moz-box-ordinal-group: $val;
  -ms-flex-order: $val;
  -webkit-order: $val;
  order: $val;
}

.o-paymentRequest__tableControls {
  @include flexbox();
  width: 100%;
  justify-content: flex-end;

  .m-paymentRequest__tableFilterWrapper {
    position: relative;
    font-weight: 600;
    color: $dark-blue;
    width: 200px;
    .a-paymentRequest__tableFilter {
      width: 100%;
      height: 36px;
      outline: none;
      background-color: $dark-blue;
      color: #f5f9fa;
      text-transform: capitalize !important;
      padding: 0 10px;
      border: none;
      border-right: 5px solid $dark-blue;
      border-radius: 3px;
      cursor: pointer;

      option {
        background-color: #f5f9fa;
        color: $dark-blue;
      }
    }

    i {
      position: absolute;
      right: 12px;
      top: 6px;
      color: #f5f9fa;
    }
  }
}

.o-singlePayment__wrapper {
  @include flexbox();
  width: 100%;
  flex-wrap: wrap;
  justify-content: flex-start;

  .o-singlePayment__container {
    width: 50%;
    min-width: 480px;

    h4 {
      margin: 0;
      color: $dark-blue;
      font-weight: 600;
      text-transform: capitalize;
    }

    h2 {
      margin: 0;
      text-transform: capitalize;
      font-size: 24px;
      color: $dark-blue;
      font-weight: bold;
    }

    .m-singlePayment__balance {
      margin-bottom: 40px;
      h2 {
        color: #56bd66;
      }
    }

    .o-singlePayment__successWrapper {
      @include flexbox();
      justify-content: center;
      flex-wrap: wrap;
      width: 420px;
      height: 300px;

      i {
        font-size: 96px;
        width: 96px;
        height: 96px;
        color: #56bd66;
      }

      h3 {
        width: 100%;
        font-weight: 600;
        text-align: center;
        color: $dark-blue;
      }
    }

    .o-singlePayment__transferForm {
      width: fit-content;
      h2 {
        margin: 0 0 20px 0;
      }

      .m-singlePayment__transferFormInput {
        @include flexbox();
        width: 100%;
        margin: 0 0 10px 0;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-items: center;

        i {
          align-self: start;
          height: 36px;
          line-height: 36px;
          margin: 0 10px 0 0;
        }

        select,
        input,
        textarea {
          width: 420px;
          height: 36px;
          border: none;
          border-radius: 3px;
          background-color: #dbeff4;
          outline: none;
          padding: 0 15px 0 15px;
          font-weight: 600;

          &:read-only {
            cursor: not-allowed;
          }
        }

        textarea {
          padding-top: 10px;
          height: 72px;
        }
      }

      .m-singlePayment__transferFormActions {
        @include flexbox();
        justify-content: flex-end;
        align-items: center;
        width: 100%;
        .a-singlePayment__transferFormButton {
          margin: 0 0 0 20px;
          height: 36px;
          border: none;
          background-color: $dark-blue;
          outline: none;
          cursor: pointer;
          font-weight: 600;
          color: white;
          padding: 5px 40px;
          text-transform: capitalize;
          border-radius: 3px;

          &.-negative {
            background-color: red;
          }

          &.-positive {
            background-color: #56bd66;
          }

          &:disabled {
            background-color: $backgroundTextColor;
            cursor: not-allowed;
          }
        }
      }
    }

    .o-singlePayment__othersAssignedWrapper {
      width: 100%;
      h2 {
        margin: 0 0 10px 0;
      }
      .o-singlePayment__othersAssignedItem {
        @include flexbox();
        justify-content: flex-start;
        align-items: center;
        margin: 0 0 10px 0;
        .a-singlePayment__othersAssignedItemAvatar {
          width: 60px;
          height: 60px;
          border-radius: 30px;
          background-color: $dark-blue;
          background-size: cover;
          margin: 0 20px 0 0;
        }

        .m-singlePayment__othersAssignedItemBody {
          @include flexbox();
          justify-content: center;
          align-items: center;
          color: $dark-blue;

          p {
            font-weight: 600;
            margin: 0 10px 0 0;
          }

          i {
            margin: 0 10px 0 0;
            line-height: 14px;
          }
        }
      }
    }
  }
}
