.o-admin__table-container table {
  border-spacing: 1;
  border-collapse: collapse;
  background: white;
  border-radius: 10px;
  overflow: hidden;
  width: 100%;
  margin: 0 auto;
  position: relative;
}
.o-admin__table-container table * {
  position: relative;
}
.o-admin__table-container table td,
.o-admin__table-container table th {
  padding-left: 8px;
}
.o-admin__table-container table thead tr {
  height: 60px;
  background: #003952;
}
.o-admin__table-container table tbody tr {
  height: 50px;
}
.o-admin__table-container table tbody tr:last-child {
  border: 0;
}
table td,
table th {
  text-align: left;
}
table td.l,
table th.l {
  text-align: right;
}
table td.c,
table th.c {
  text-align: center;
}
table td.r,
table th.r {
  text-align: center;
}

.o-admin__table-head th {
  color: #ffffff !important;
  line-height: 1.2;
  font-weight: unset;
}
.o-admin__table-container tbody tr {
  font-size: 15px;
  border-bottom: 1px solid #80808045;
  color: #808080;
  line-height: 1.2;
  font-weight: unset;
  cursor: pointer;
}
.o-admin__table-container tbody tr:hover {
  background: #e8e8e89e;
  border-radius: 5px;
  transition: 0.5s;
}

.o-admin__table-column1 {
  padding: 20px;
}

.ui.button.o-admin__add-button {
  background: #003952;
  color: #ffffff;
  margin-bottom: 24px;
}
.o-admin__header-wrapper {
  display: flex;
  justify-content: space-between;
}
.o-admin__details-header {
  border-bottom: 1px solid #2b2b2b;
  width: 30%;
}
.o-admin__details-buttons {
  margin: 32px 0;
}

@media only screen and (min-width: 1200px) {
  .ui.mini.modal.o-admin__edit-modal,
  .ui.mini.modal.o-admin__block-modal {
    width: 450px;
  }
}
.o-admin__personal-details {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.ant-table-thead > tr > th {
  font-size: 12px;
  color: #507a8c !important;
  font-weight: 600;
}
