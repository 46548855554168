$primary-color: #009fe3;
$dark-blue: #003952;
$shadow-blue: #f5f9fa;

$activeSideNavBgColor: #05acf3;

$backgroundTextColor: #7c95a0;

@mixin flexbox() {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

@mixin flex($values) {
  -webkit-box-flex: $values;
  -moz-box-flex: $values;
  -webkit-flex: $values;
  -ms-flex: $values;
  flex: $values;
}

@mixin order($val) {
  -webkit-box-ordinal-group: $val;
  -moz-box-ordinal-group: $val;
  -ms-flex-order: $val;
  -webkit-order: $val;
  order: $val;
}

.o-overview__wrapper {
  h2 {
    color: $backgroundTextColor;
    font-weight: 700;
    font-size: 30px;
    text-transform: capitalize;
  }
}

.o-overviewStats__wrapper {
  @include flexbox();
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.o-statisticCard__wrapper {
  width: 30%;
  @include flexbox();
  justify-content: center;
  height: 160px;
  border-radius: 5px;
  box-shadow: 0px 5px 12px rgba(158, 203, 214, 0.31);

  .o-statisticCard__container {
    width: 80%;
    @include flexbox();
    flex-wrap: wrap;
    color: #fff;
    .m-statisticCard__data {
      margin-top: 30px;
      width: 100%;
      @include flexbox();
      justify-content: space-between;
      h4 {
        font-weight: 600;
        font-size: 32px;
        color: #fff;
      }
    }

    h3 {
      font-weight: 600;
      font-size: 18px;
      color: #fff;
    }
  }
}
