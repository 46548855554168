@import "../variables.scss";

.yesButton {
  margin: 0 0 0 10px;
}

.confirmBoxWrapper {
  @include flexbox();
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  .confirmBoxContainer {
    @include flexbox();
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    padding: 20px 20px 0 20px;
    flex-wrap: wrap;

    & > h4 {
      font-size: 18px;
      width: 100%;
      font-family: "Poppins", sans-serif;
      font-weight: 800;
      color: $dark-blue;
    }
    .confirmBoxAction {
      @include flexbox();
      justify-content: flex-end;
      align-items: center;
      width: 100%;
      padding: 10px 0;

      .confirmBoxButton {
        text-align: center;
        outline: none;
        padding: 12px 20px;
        color: $shadow-blue;
        border: none;
        border-radius: 3px;
        margin: 0 0 0 10px;
        font-family: "Poppins", sans-serif;
        font-weight: 600;
        background-color: $dark-blue;
        cursor: pointer;
        &.-negative {
          background-color: #dd5954;
        }

        &:disabled {
          background-color: #7c95a0;
        }

        &.-positive {
          background-color: #56bd66;
        }
      }
    }
  }
}
