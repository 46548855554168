@import '@laborhack/base-styles/lib/variables.scss';

.emailInput {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.getClientButton {
  margin-left: $margin-normal;
}

.clientInfo {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: $padding-normal;
  border: 1px solid $success-color;
  border-radius: 0.5rem;

  .clientName {
    h4 {
      font-weight: 600;
      color: $dark-blue;

      i {
        color: $success-color;
      }
    }

    p {
      font-size: $font-sm;
      color: $backgroundTextColor;
    }
  }

  .actions {
    justify-self: end;

    i {
      cursor: pointer;
    }
  }
}
