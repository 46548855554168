.o-tradesmen__table-head th {
  color: #ffffff !important;
  line-height: 1.2;
  font-weight: unset;
}
.o-tradesmen__table-container tbody tr {
  font-size: 15px;
  color: #808080;
  border-bottom: 1px solid #80808045;
  line-height: 1.2;
  font-weight: unset;
}
.o-tradesmen__table-container tbody tr:hover {
  background: #e8e8e89e;
  border-radius: 5px;
  transition: 0.5s;
}
.tradesman__point:hover {
  color: #009fe3;
  text-decoration: underline;
}

.o-tradesmen__table-column1 {
  padding: 20px;
}

.ui.button.o-tradesmen__add-button {
  background: #003952;
  color: #ffffff;
  margin-bottom: 24px;
}
.o-tradesmen__header-wrapper {
  display: flex;
  justify-content: space-between;
}
.o-tradesmen__details-header {
  border-bottom: 1px solid #2b2b2b;
  width: 30%;
}
.o-tradesmen__details-buttons {
  margin: 32px 0;
}

@media only screen and (min-width: 1200px) {
  .ui.mini.modal.o-tradesmen__edit-modal,
  .ui.mini.modal.o-tradesmen__block-modal {
    width: 450px;
  }
}
.o-tradesman__personal-details {
  border-bottom: 1px solid #dadada;
  .tradesman-point__details h3 {
    font-size: 16px;
    font-weight: 600;
  }
}

.o-tradesman__personal-details,
.o-tradesmen__location-details {
  display: flex;
  flex-wrap: wrap;
  padding: 24px 0;
  width: 80%;
}
.o-tradesmen__personal-right,
.o-tradesmen__location-right {
  padding: 0 32px;
  width: 80%;
  h3 {
    font-size: 16px;
    font-weight: 600;
  }
}
.o-tradesmen__location-left,
.o-tradesmen__personal-left {
  width: 20%;
}
.ant-table-thead > tr > th {
  font-size: 12px;
  color: #507a8c !important;
  font-weight: 600 !important;
}
.o-tradesman__table {
  .ant-table-row:hover {
    cursor: auto;
  }
}
.ant-table-row:hover {
  cursor: pointer;
}

.create-button {
  display: flex;
  flex-wrap: nowrap;
  button {
    margin-right: 12px;
  }
}
.modalContent {
  padding: 12px;
  h2 {
    font-weight: 600;
    margin-bottom: 0;
  }
  .formWrapper {
    padding: 12px 0;
    .modalButton {
      margin-top: 24px;
      display: flex;
      justify-content: flex-end;
    }
    .ui.form select {
      font-weight: 600;
    }
    .successMessage {
      color: green;
    }
    .errorMessage {
      color: red;
    }
  }
}
