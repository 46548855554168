@import '@laborhack/base-styles/lib/variables.scss';

.contractProItem {
  padding: $padding-normal $padding-lg;
  width: 100%;
  margin-bottom: $margin-sm;
  border-radius: $grid-unit * 2;

  &.selected {
    background-color: #56bd66;
    box-shadow: 0px 2px 16px 2px rgba(158, 203, 214, 0.6);

    .info {
      p {
        color: white;
      }
    }
  }
}

.info {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  align-items: center;

  p {
    color: $dark-blue;
    font-weight: 600;
    padding: 0;
    margin: 0;
  }
}

.conflicts {
  margin: $margin-normal 0;
}