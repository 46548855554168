@import "../../../_components/variables.scss";

.heading {
  font-size: $font-lg;
  font-weight: bold;
}

.modalContainer {
  @include flexbox();
  width: 100%;
  margin: $margin-lg 0 0 0;
  align-items: center;
  flex-wrap: nowrap;
}

.saveButton {
  margin: 0 0 0 $margin-normal;
}

.dataWrapper {
  @include flexbox();
  margin: 40px 0 30px 0;
  flex-wrap: wrap;
  padding: 20px 0 0 40px;
  h4 {
    width: 100%;
    text-transform: capitalize;
    margin-bottom: 20px;
    font-size: 18px;
    font-weight: 600;
    color: $backgroundTextColor;
  }
}

// .o-jobDetail__information {
//   @include flexbox();
//   margin: 40px 0 30px 0;
//   flex-wrap: wrap;
//   padding: 20px 0 0 40px;
//   h4 {
//     width: 100%;
//     text-transform: capitalize;
//     margin-bottom: 20px;
//     font-size: 18px;
//     font-weight: 600;
//     color: $backgroundTextColor;
//   }
//   .m-jobDetail__informationWrapper {
//     width: 30%;
//     .m-jobDetail__informationField {
//       @include flexbox();
//       align-items: center;
//       flex-wrap: wrap;
//       margin-left: 10px;
//       margin-bottom: 10px;
//       h5 {
//         width: 100%;
//         text-transform: capitalize;
//         color: $backgroundTextColor;
//         font-size: 12px;
//         line-height: 18px;
//         letter-spacing: 0.1em;
//       }

//       i {
//         margin: 0 0 0 10px;
//         cursor: pointer;
//         line-height: 1em;

//         &:hover {
//           color: $primary-color;
//         }
//       }

//       p {
//         margin: 0;
//         color: $dark-blue;
//         font-size: 14px;
//         font-weight: 600;
//         &.-large {
//           font-size: 24px;
//           line-height: 36px;
//         }
//         &.-capitalize {
//           text-transform: capitalize;
//         }
//       }
//     }
//   }
// }

.fieldHeader {
  width: 100%;
  text-transform: capitalize;
  color: $backgroundTextColor;
  font-size: $font-sm;
  line-height: $grid-unit * 4;
}

i.fieldAction {
  margin: 0 0 0 $margin-sm;
  cursor: pointer;
  line-height: $font-normal;

  &:hover {
    color: $primary-color;
    font-size: $font-lg;
  }
}

.fieldDescription {
  margin: 0;
  color: $dark-blue;
  font-size: $font-normal;
  font-weight: 600;
  &.-large {
    font-size: $font-xl;
    line-height: $grid-unit * 9;
  }
  &.-capitalize {
    text-transform: capitalize;
  }
}
.customJobFile {
  img {
    width: 75%;
  }
  video {
    width: 100%;
  }
}
.resourceView {
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}
