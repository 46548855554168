@import "@laborhack/base-styles/lib/variables";

.wrapper {
  display: grid;
  grid-template-columns: 1fr 5fr;
  gap: $grid-unit *4;
  justify-items: start;
  align-items: center;
  color: $dark-blue;
}

.label {
  margin: 0;
  font-weight: 600;
}

.days {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  justify-items: start;
  gap: $grid-unit *4;
  color: $dark-blue !important;
}