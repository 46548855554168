@import "../variables.scss";

.tab {
  padding: 0 $padding-lg;
  a {
    color: $dark-blue !important;

    &.active {
      border-width: 5px !important;
      border-color: $dark-blue !important;
    }
  }
}
