@import '~@laborhack/base-styles/src/variables';

.wrapper {
  margin: $margin-lg $margin-normal $margin-normal $margin-normal;
}

.header {
  margin: $margin-normal 0;
  @include flexbox();
  justify-content: space-between;

  .actions {
    @include flexbox();
    flex-wrap: nowrap;

    .btn {
      margin: 0 0 0 $margin-normal;
    }
  }
}

.content {
  width: 100%;
  height: 600px;
  overflow-y: auto;
}
