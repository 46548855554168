@import '@laborhack/base-styles/lib/variables.scss';

.wrapper {
  padding: $padding-lg;

  h3 {
    font-weight: bold;
    color: $dark-blue;
  }
}

.form {
  margin: $margin-lg 0;
  .field {
    margin: $margin-normal 0;
    max-width: 320px;

    label {
      display: block;
      font-weight: 600;
      color: $dark-blue !important;
      margin-bottom: $margin-sm;
    }
  }
}

.input {
  width: 100%;
  div {
    width: 100%;
  }
  input {
    width: 100% !important;
  }
}

.contract {
  display: grid;
  grid-template-columns: 2fr 1fr;
  align-items: flex-start;
  gap: $grid-unit * 2;
}

.datePicker {
  height: 36px;
  width: 100%;

  .react-date-picker__wrapper {
    width: 100%;
    border-radius: 3px;
    padding: 0 10px;
    border-color: $backgroundTextColor;
    background-color: white !important;
    color: #fff !important;
    button {
      margin: 0 !important;

      &:focus {
        outline: none;
      }
    }

    .react-calender {
      button {
        margin-bottom: 0px !important;
      }
    }
  }
}
