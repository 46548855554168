@import '~@laborhack/base-styles/lib/variables';

.wrapper {
  @include flexbox();
  align-items: center;
  justify-content: center;
  margin: $margin-lg 0;

  .body {
    @include flexbox();
    align-items: center;
    justify-content: center;
    flex-direction: column;
    max-width: $grid-unit * 120;
    flex-wrap: wrap;
  }

  h2 {
    font-size: $font-xl;
    font-weight: bold;
    color: $dark-blue;
    margin: 0 0 $margin-normal 0;
  }

  p {
    color: $backgroundTextColor;
  }

  img {
    width: $grid-unit * 90;
    margin-bottom: $margin-lg;
  }
}
