@import "../../../_components/variables.scss";

.heading {
  font-size: $font-lg;
  font-weight: bold;
}

.modalContainer {
  @include flexbox();
  width: 100%;
  margin: $margin-lg 0 0 0;
  align-items: flex-end;
  flex-wrap: nowrap;
}

.button {
  margin: 0 0 0 $margin-normal;
}

.form {
  padding: $padding-normal;
  width: 100%;
}

.formHeading {
  font-size: $font-lg;
  font-weight: bold;
  color: $dark-blue;
  margin: 0 0 $margin-normal 0;
}

.textArea {
  border-color: $backgroundTextColor;
  background: $shadow-blue;
  padding: $padding-sm;
  color: $dark-blue;
  width: 100%;

  &:focus {
    outline: none;
  }
}

.field {
  @include flexbox();
  flex-wrap: wrap;
  width: 100%;
  margin: 0 0 $margin-normal 0;

  p {
    font-size: $font-sm;
    font-weight: bold;
    margin: 0 0 $margin-sm 0;
    width: 100%;
    color: $backgroundTextColor;
  }
}

.rating {
  & > i {
    &:focus {
      outline: none;
    }
  }
}
