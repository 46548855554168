@import "../../../../_components/variables.scss";

.dataFieldContainer {
  @include flexbox();
  align-items: center;
  flex-wrap: wrap;
  margin: 0 0 $margin-sm $margin-sm;
}

.dataSection {
  width: 30%;
}
