@import '@laborhack/base-styles/lib/variables';

.field {
  display: grid;
  grid-template-columns: 2fr 3fr;
  max-width:480px;
  margin-bottom: $margin-normal;
  align-items: center;

  p {
    color: $dark-blue;
    font-weight: 500;
    margin: 0;
  }

  .label {
    color: $backgroundTextColor;
    font-weight: 600;
  }
}
