@import '~@laborhack/base-styles/lib/variables';

.wrapper {
  padding: $padding-lg;

  h2 {
    font-weight: bold;
    font-size: $font-lg;
    color: $dark-blue;
  }
}

.paymentProviderList {
  @include flexbox();
  margin: $margin-normal 0;
  align-items: center;
  justify-content: center;

  @media (max-width: $mobile) {
    flex-wrap: wrap;
  }
}

.totals {
  max-width: $grid-unit * 80;
  margin-top: $margin-normal;
  margin-bottom: $margin-normal;
  .field {
    @include flexbox();
    align-items: center;
    justify-content: space-between;
    margin-bottom: $margin-sm;

    p {
      color: $dark-blue;
      margin: 0;
    }
  }
}

.button {
  @media (max-width: $mobile) {
    flex-wrap: wrap;
    width: 100%;
    padding-left: $padding-sm !important;
    padding-right: $padding-sm !important;
  }
}
