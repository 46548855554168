@import '@laborhack/base-styles/lib/variables.scss';

.wrapper {
  display: grid;
  grid-template-columns: $grid-unit * 8 1fr;
  align-items: center;
  margin-bottom: $margin-normal;

  .step {
    @include flexbox();
    width: $grid-unit * 6;
    height: $grid-unit * 6;
    justify-content: center;
    align-items: center;
    background-color: $dark-blue;
    border-radius: 50%;
    color: white;
    font-weight: bold;
  }

  .label {
    font-size: $font-normal;
    font-weight: bold;
    color: $dark-blue;
  }
}
