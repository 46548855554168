@import '~@laborhack/base-styles/lib/variables.scss';

.wrapper {
  @include flexbox();
  align-items: center;
}

.cancel {
  margin: 0 $margin-normal 0 0;
}
