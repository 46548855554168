.o-categoies__container {
  border: 1px solid #b1a2a2;
  padding: 12px;
  border-radius: 4px;
  margin: 12px;
}

.o-categories__table-container tbody tr {
  font-size: 15px;
  border-bottom: 1px solid #80808045;
  color: #808080;
  line-height: 1.2;
  font-weight: unset;
}
.o-categories__table-head th {
  color: #ffffff !important;
  line-height: 1.2;
  font-weight: unset;
}
.o-categories__header-wrapper {
  display: flex;
  justify-content: space-between;
}
.o-categories__details-header {
  border-bottom: 1px solid #2b2b2b;
  width: 30%;
}
.o-categories__details-buttons {
  margin: 32px 0;
}

.o-categories__table-column1 {
  padding: 20px;
}
.ui.button.o-categories__add-button {
  background: #003952;
  color: #ffffff;
  margin-bottom: 24px;
}
.o-categories__table-column-no-data {
  text-align: center;
}
.o-categories__table-container tbody tr:hover {
  background: #e8e8e89e;
  border-radius: 5px;
  transition: 0.5s;
}
.ant-table-thead > tr > th {
  font-size: 12px;
  color: #507a8c !important;
  font-weight: 600 !important;
}
