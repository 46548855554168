@import "../variables.scss";

.cartButton {
  display: flex;
  cursor: pointer;
  font-weight: 700;
  align-items: center;
  justify-content: space-around;
  padding: $padding-sm $padding-lg;
  height: 36px;
  background-color: $dark-blue;
  border-radius: $grid-unit;
  border: none;
  font-family: "Poppins";
  color: $shadow-blue;

  &:focus {
    outline: none;
  }

  &.fullWidth {
    width: 100%;
  }

  &.link {
    background: none;
    width: fit-content;
    color: $primary-color;
    border: none;
    padding: 0;
  }

  &.outline {
    color: $primary-color;
    background: none;
    border: 1px solid $primary-color;
  }

  &.basic {
    background-color: $basic-bg-color;
    color: $deep-grey;

    &.link {
      background: none;
      width: fit-content;
      border: none;
      padding: 0;
    }

    &.inverted {
      background-color: white;
      color: $dark-blue;
    }

    &.outline {
      color: $deep-grey;
      background: none;
      border: 1px solid $deep-grey;
    }
  }

  &.success {
    border: 1px solid $success-color;
    background: $success-color;
    color: $shadow-blue;

    &.link {
      background: none;
      width: fit-content;
      color: $success-color;
      border: none;
      padding: 0;
    }

    &.outline {
      color: $success-color;
      background: none;
      border: 1px solid $success-color;
    }
  }

  &.negative {
    border: 1px solid $negative-color;
    background: $negative-color;
    color: $shadow-blue;

    &.link {
      background: none;
      width: fit-content;
      color: $negative-color;
      border: none;
      padding: 0;
    }

    &.outline {
      color: $negative-color;
      background: none;
      border: 1px solid $negative-color;
    }
  }

  &:disabled {
    cursor: not-allowed;
    background-color: $inactive-bg-color !important;
    color: $inactive-text-color !important;
    border: none !important;
  }

  &.loading {
    color: $deep-grey !important;
  }
}
