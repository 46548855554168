.o-clients__table-head th {
  color: #fff !important;
  line-height: 1.2;
  font-weight: unset;
}
.o-clients__table-container tbody tr {
  font-size: 15px;
  border-bottom: 1px solid #80808045;
  color: #808080;
  line-height: 1.2;
  font-weight: unset;
  cursor: pointer;
}
.o-clients__table-container tbody tr:hover {
  background: #e8e8e89e;
  border-radius: 5px;
  transition: 0.5s;
}

.o-clients__table-column1 {
  padding: 20px;
}

.ui.button.o-add-button {
  background: #003952;
  color: #ffffff;
}
.o-clients__header-wrapper {
  display: flex;
  justify-content: space-between;
}
.o-clients__details-header {
  border-bottom: 1px solid #2b2b2b;
  width: 30%;
}
.o-clients__details-buttons {
  margin: 32px 0;
}
.o-add-wrapper {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 24px;
  .refresh-btn {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    color: #003952;
    cursor: pointer;
    p {
      margin: 0 10px 0 0;
      font-weight: 600;
    }
    .icon {
      cursor: pointer;
      height: 14px;
      line-height: 14px;
      margin: 0px;
    }
  }
}

@media only screen and (min-width: 1200px) {
  .ui.mini.modal.o-clients__edit-modal,
  .ui.mini.modal.o-clients__block-modal {
    width: 450px;
  }
}
.o-clients__personal-details {
  border-bottom: 1px solid #dadada;
}

.o-clients__personal-details,
.o-clients__location-details {
  display: flex;
  flex-wrap: wrap;
  padding: 24px 0;
  width: 80%;
}
.o-clients__personal-right,
.o-clients__location-right {
  padding: 0 32px;
}
.o-clients__location-left,
.o-clients__personal-left {
  width: 20%;
}
.ant-table-thead > tr > th {
  font-size: 12px;
  color: #507a8c !important;
  font-weight: 600 !important;
}
