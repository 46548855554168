@import '@laborhack/base-styles/lib/variables';

.wrapper {
  margin: $margin-lg 0;
}

.actions {
  display: flex;
  justify-content: flex-end;
  margin-bottom: $margin-normal;
}
