@import '@laborhack/base-styles/lib/variables.scss';

.actions {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: $margin-lg;

  & > button:last-child {
    margin-left: $margin-normal;
  }
}
