// layout
$grid-unit: 4px;
$padding-xs: $grid-unit;
$padding-sm: $grid-unit * 2;
$padding-normal: $grid-unit * 4;
$padding-lg: $grid-unit * 8;
$padding-xl: $grid-unit * 16;

$margin-xs: $grid-unit;
$margin-sm: $grid-unit * 2;
$margin-normal: $grid-unit * 4;
$margin-lg: $grid-unit * 8;
$margin-xl: $grid-unit * 16;

$border-xs: $grid-unit;
$border-sm: $grid-unit * 2;
$border-normal: $grid-unit * 4;
$border-lg: $grid-unit * 8;
$border-xl: $grid-unit * 16;

// font-sizes
$font-xs: 0.71em;
$font-sm: 0.85em;
$font-normal: 1em;
$font-lg: 1.28em;
$font-xl: 1.71em;

$primary-color: #009fe3;
$dark-blue: #003952;
$shadow-blue: #f5f9fa;
$backgroundColor: #d8eaf1;
$accent: #ed6e5c;
$activeSideNavBgColor: #05acf3;
$backgroundTextColor: #7c95a0;
$island-shadow: #9ecbd64f;
$deep-grey: #595656;
$light-grey: #a4a4a4;
$inactive-text-color: #d0d0d0;
$inactive-bg-color: #ebebeb;
$basic-bg-color: #dadada;
$success-color: #56bd66;
$negative-color: #ff5f58;
$light-primary: #ff7c6a;
$progress-bg-color: #f4e3b3;
$progress-text-color: #c79f29;
$bright-red-color: #ff080830;
$deep-red-color: #bb2c2c;
$light-bg-green: #6bcb79;
$light-bg-yellow: #f1c644;
$light-basic-text-color: #797979;

@mixin flexbox() {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

@mixin flex($values) {
  -webkit-box-flex: $values;
  -moz-box-flex: $values;
  -webkit-flex: $values;
  -ms-flex: $values;
  flex: $values;
}

@mixin order($val) {
  -webkit-box-ordinal-group: $val;
  -moz-box-ordinal-group: $val;
  -ms-flex-order: $val;
  -webkit-order: $val;
  order: $val;
}

h1,
h2,
h3,
h4,
h5,
h6,
span,
input,
label,
div,
p {
  font-family: "Poppins", sans-serif !important;
}

input {
  font-weight: bold;
}
