$primary-color: #009fe3;
$dark-blue: #003952;
$shadow-blue: #f5f9fa;
$backgroundColor: #d8eaf1;

$activeSideNavBgColor: #05acf3;

$backgroundTextColor: #7c95a0;

@mixin flexbox() {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

.o-invoice__tableControls {
  @include flexbox();
  width: 100%;
  justify-content: flex-end;

  .o-invoice__tableFilterWrapper {
    position: relative;
    font-weight: 600;
    color: $dark-blue;
    width: 200px;
    .o-invoice__tableFilter {
      width: 100%;
      height: 36px;
      outline: none;
      background-color: $dark-blue;
      color: #f5f9fa;
      text-transform: capitalize !important;
      padding: 0 10px;
      border: none;
      border-right: 5px solid $dark-blue;
      border-radius: 3px;
      cursor: pointer;
    }
  }
}

// Invoice view styles

$primary-color: #003952;
$backgroundTextColor: #7c95a0;
$accent: #ed6e5c;

@mixin flexbox() {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

@mixin flex($values) {
  -webkit-box-flex: $values;
  -moz-box-flex: $values;
  -webkit-flex: $values;
  -ms-flex: $values;
  flex: $values;
}

@mixin order($val) {
  -webkit-box-ordinal-group: $val;
  -moz-box-ordinal-group: $val;
  -ms-flex-order: $val;
  -webkit-order: $val;
  order: $val;
}
.row {
  @include flexbox();
  padding: 10px 0 0 0;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.wrapper {
  @include flexbox();
  justify-content: center;
  width: 100%;
  color: $primary-color;
  font-family: 'Poppins', sans-serif;

  h4,
  h3 {
    font-weight: 600;
  }

  p {
    color: $backgroundTextColor;
  }

  h3,
  h4,
  p {
    font-family: 'Poppins', sans-serif;
    line-height: 1.53;
    margin: 0;
  }

  .container {
    box-shadow: 0px 5px 12px rgba(158, 203, 214, 0.31);
    padding: 40px 20px;
    width: 100%;
    max-width: 720px;

    .header {
      @include flexbox();
      width: 100%;
      justify-content: space-between;
      align-items: flex-start;

      .logo {
        h1 {
          font-family: 'Poppins', sans-serif;
          line-height: 1;
          color: $accent;
          margin: 0;
        }
      }

      .address {
        article {
          text-align: right;
          p {
            margin: 0;
          }
        }
      }
    }

    .mid-section {
      @media (max-width: 576px) {
        display: none;
      }
      margin-top: 48px;
      @include flexbox();
      width: 100%;
      justify-content: space-between;

      .invoice-details {
        h3 {
          font-size: 24px;
        }
        text-align: right;
        .invoice-field {
          margin: 10px 0;
        }
      }
    }

    .mid-section-sm {
      width: 100%;
      display: none;
      margin-top: 48px;
      flex-wrap: wrap;
      @media (max-width: 576px) {
        @include flexbox();
      }
    }

    .purchase-overview {
      @include flexbox();
      justify-content: flex-end;
      flex-wrap: wrap;
      margin-top: 72px;
      width: 100%;

      .purchase-table {
        width: 100%;
        table {
          width: 100%;

          th {
            font-weight: 600;
            color: $primary-color;
          }

          td {
            text-transform: capitalize;
            font-weight: 400;
            color: $primary-color;
            border-collapse: collapse;
            border-spacing: 0px;
            border-top: 1px solid #f3f9fc;

            div {
              h4 {
                color: $primary-color;
              }
            }
          }

          th,
          td {
            font-size: 14px;
            padding: 5px 0;
            text-align: left;
            &:last-child {
              vertical-align: top;
              width: 40%;
              text-align: right;
            }
          }
        }
      }

      .purchase-calculation {
        border-top: 2px solid #c5d2d8;
        width: 300px;

        @media (max-width: 576px) {
          width: 100%;
        }

        p.total-due {
          font-size: 24px;
          color: $accent;
          font-weight: 800;
        }
      }
    }

    .payment-link {
      @include flexbox();
      width: 100%;
      justify-content: flex-end;
      margin: 20px 0 0 0;

      a {
        display: block;
        text-align: center;
        line-height: 42px;
        background-color: $accent;
        border: none;
        border-radius: 5px;
        color: white;
        width: 300px;
        height: 42px;
        font-family: 'Poppins', sans-serif;
        font-weight: 600;

        @media (max-width: 576px) {
          width: 100%;
        }

        &:focus {
          outline: none;
        }
      }
    }

    footer {
      margin-top: 40px;
      font-weight: 400;
      text-align: center;
    }
  }
}

.sm-none {
  @media (max-width: 576px) {
    display: none;
  }
}

.m-invoiceView__actions {
  width: 100%;
  @include flexbox();

  button {
    margin-right: 8px;
  }
}
