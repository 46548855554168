@import '~@laborhack/base-styles/lib/variables.scss';

.wrapper {
  margin: $margin-lg 0 0 0;

  h3 {
    font-weight: 600;
    color: $dark-blue;
  }
}

.information {
  margin: $margin-normal 0 0 $margin-normal;
}

.field {
  margin: 0 0 $margin-sm 0;
  h5 {
    font-weight: 400;
    color: rgba($color: $dark-blue, $alpha: 0.7);
    margin: 0;
  }

  p {
    font-weight: 600;
    color: $dark-blue;
  }
}
