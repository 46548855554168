@import '@laborhack/base-styles/lib/variables.scss';

.proListItem {
  display: flex;
  align-items: center;
  margin-top: $margin-sm;

  p {
    margin-right: $margin-sm !important;
  }

  .proListItemImage {
    display: flex;
    justify-content: center;
    align-items: center;
    width: $grid-unit * 8;
    height: $grid-unit * 8;
    border-radius: 50%;
    background-color: $dark-blue;
    margin-right: $margin-sm;
    color: white;
    font-weight: 600;
  }
}
