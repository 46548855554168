@import '~@laborhack/base-styles/lib/variables.scss';

.wrapper {
  margin: $margin-lg 0 0 0;

  .header {
    @include flexbox();
    align-items: center;
    h3 {
      font-weight: 600;
      color: $dark-blue;
      margin: 0 $margin-lg 0 0;
    }
  }
}

.permissions {
  margin: $margin-normal 0 0 $margin-normal;
}

.permission {
  @include flexbox();
  align-items: center;
  margin: 0 0 $margin-xs 0;
  input {
    margin: 0 $margin-xs 0 0;
  }

  label {
    color: $dark-blue;
  }
}
