@import '../../../../_components/variables.scss';

.checklistItem {
  @include flexbox();
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: $margin-sm;

  p {
    font-size: $font-sm;
    color: $dark-blue;
    margin: 0;
  }

  i {
    line-height: 1;
    font-size: $font-normal;
  }
}

.wrapper {
  @include flexbox();
  width: 100%;
  align-items: flex-start;
  margin: 0 0 $margin-normal 0;
}

.toggle {
  padding: $padding-normal;
  i {
    cursor: pointer;
    line-height: 1;
    font-size: $font-lg;
    color: $backgroundTextColor;

    &.active {
      color: $dark-blue;
    }
  }
}

.body {
  width: 100%;

  h3 {
    margin: 0 0 $margin-sm 0;
    color: $dark-blue;
    font-weight: 700;
  }
}

.inner {
  @include flexbox();
  padding: 0 $padding-normal $padding-sm $padding-normal;
  flex-wrap: nowrap;
  align-items: center;
  width: 100%;

  & > div {
    width: 50%;
  }

  .innerImage {
    @include flexbox();
    justify-content: center;
  }
}

.footer {
  @include flexbox();
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  & > p {
    margin: 0;
    color: $backgroundTextColor;
  }
}
