@import '~@laborhack/base-styles/lib/variables';

.summary {
  width: 100%;
}

.address {
  width: 100%;
  margin: 0 0 $margin-lg 0;
}

.header {
  width: 100%;
  min-height: $grid-unit * 14;
  margin: $margin-normal 0 0 0;
  h3 {
    font-weight: 700;
    color: $dark-blue;
    width: 100%;
  }

  p {
    color: $dark-blue;
    width: 100%;
  }
}

.cartItems {
  width: 100%;
  margin: $margin-normal 0;
}
