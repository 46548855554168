@import '@laborhack/base-styles/lib/variables.scss';

.selectProWrapper {
  width: 100%;
  padding: $padding-lg;

  h3 {
    font-weight: 600;
    color: $dark-blue;
  }

  .listHeader {
    padding: 0 20px;
    margin: $margin-normal 0 $margin-sm 0;
    display: grid;
    grid-template-columns: 35% 20% 20% 20% 5%;

    p {
      margin: 0;
      padding: 0;
      color: $backgroundTextColor;
    }
  }

  .list {
    width: 100%;
    margin: 0;
    .emptyList {
      @include flexbox();
      width: 100%;
      justify-content: center;
    }
  }
}
