@import '~@laborhack/base-styles/lib/variables.scss';

.history {
  h3 {
    font-weight: 600;
    color: $dark-blue;
  }
}

.historyItem {
  @include flexbox();
  width: 100%;
  align-items: center;
  margin: 0 0 $margin-normal 0;

  p {
    color: $dark-blue;
    font-weight: 600;
    font-size: $font-normal;
  }

  & > * {
    margin: 0 $margin-normal 0 0;
  }
}

.historyAvatar {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  border: 2px solid $dark-blue;
  background-color: $backgroundTextColor;
}
