@import '~@laborhack/base-styles/lib/variables';

.counter {
  max-width: 100px;
  width: 100%;
  @include flexbox();
  justify-content: space-between;
  align-items: center;

  .button {
    cursor: pointer;
    color: $dark-blue;
    line-height: 1;

    &.disabled {
      color: $inactive-text-color;
      cursor: not-allowed;
    }
  }

  .count {
    width: 32px;
    height: 32px;
    @include flexbox();
    justify-content: center;
    align-items: center;
    border-radius: $grid-unit * 1.5;
    border: 2px solid $backgroundTextColor;
    font-weight: 600;
    font-size: $font-normal;
    color: $dark-blue;
  }
}

.wrapper {
  @include flexbox();
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  width: 100%;
  height: $grid-unit * 24;
  padding: $padding-sm $padding-normal $padding-sm $padding-sm;
  border-radius: $grid-unit * 3;
  box-shadow: 0px 0px 18px rgba(0, 48, 69, 0.15);
  margin: $margin-normal 0;

  .partition {
    @include flexbox();
    width: 50%;
    align-items: center;

    &.quantity {
      justify-content: center;
      width: 25%;
    }
    &.actions {
      justify-content: flex-end;
      width: 25%;

      .remove {
        line-height: 1;
        cursor: pointer;
        color: $backgroundTextColor;
        &:hover {
          color: $negative-color;
        }

        @media (max-width: $tablet) {
          color: $negative-color;
        }
      }
    }
  }
}

.image {
  min-width: $grid-unit * 20;
  min-height: $grid-unit * 20;
  background-size: cover;
  border-radius: $grid-unit * 1.5;
}

.itemInformation {
  margin: 0 0 0 $margin-normal;
  h6 {
    text-transform: capitalize;
    font-size: 14px;
    color: $dark-blue;
  }
}
