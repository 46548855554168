@import "../../../../_components/variables.scss";

.field {
  @include flexbox();
  align-items: flex-end;
  flex-wrap: nowrap;

  i {
    margin: 0 0 $margin-sm $margin-sm;
    color: $dark-blue;
    cursor: pointer;
  }
}

.wrapper {
  margin: $margin-normal 0;
}
