$primary-color: #009fe3;
$dark-blue: #003952;
$shadow-blue: #f5f9fa;

$activeSideNavBgColor: #05acf3;

$backgroundTextColor: #7c95a0;

@mixin flexbox() {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

@mixin flex($values) {
  -webkit-box-flex: $values;
  -moz-box-flex: $values;
  -webkit-flex: $values;
  -ms-flex: $values;
  flex: $values;
}

@mixin order($val) {
  -webkit-box-ordinal-group: $val;
  -moz-box-ordinal-group: $val;
  -ms-flex-order: $val;
  -webkit-order: $val;
  order: $val;
}

.o-login__headerWrapper {
  @include flexbox();
  width: 100%;
  height: 100vh;
  background: url(../images/bg.jpg);
  background-size: cover;

  .o-login__headerDimmer {
    @include flexbox();
    width: 100%;
    height: 100vh;
    background: hsla(0, 0, 24, 0.31);

    h1 {
      margin: 10px 0 0 20px;
      padding: 0;
      font-family: "Poppins", sans-serif;
      font-weight: 700;
      color: #fff;
    }
  }
}

.o-login__formWrapper {
  @include flexbox();
  width: 100%;
  height: 100vh;
  align-items: center;
  font-family: "Poppins", sans-serif !important;

  .m-login__heading {
    margin: 0 0 100px 0;

    h3 {
      text-transform: capitalize;
      margin: 0;
      padding: 0;
      color: $dark-blue;
      font-family: "Poppins", sans-serif !important;
      font-weight: 700;
      font-size: 36px;
    }

    .bar {
      background-color: $primary-color;
      height: 5px;
      width: 60px;

      &.-md {
        width: 120px;
      }
    }
  }

  .a-login__input {
    text-transform: capitalize;

    label {
      text-transform: capitalize !important;
      color: $primary-color !important;
    }

    input {
      color: $dark-blue !important;
      font-family: "Poppins", sans-serif !important;
      font-weight: 600;
      height: 42px;
    }
  }

  .m-login__passwordField {
    position: relative;

    label {
      color: $primary-color !important;
      text-transform: capitalize;
      font-weight: 600;
    }

    input {
      color: $dark-blue !important;
      font-family: "Poppins", sans-serif !important;
      font-weight: 600;
      height: 48px;
    }

    i {
      cursor: pointer;
      position: absolute;
      right: 10px;
      bottom: 18px;
    }
  }

  .a-login__button {
    text-transform: capitalize;
    margin: 50px 0 0 0;
    font-family: "Poppins", sans-serif;
    background-color: $primary-color;
    color: #fff;
    height: 48px;

    &:hover {
      background-color: $primary-color !important;
      color: #fff;
    }
  }

  .m-login__formOptions {
    @include flexbox();
    text-transform: capitalize;
    margin: 10px 0 0 0;
    width: 100%;
    justify-content: space-between;
    font-weight: 600;

    label {
      color: $dark-blue !important;
    }

    p {
      cursor: pointer;
      width: fit-content;
      color: $primary-color;
    }
  }
}
