@import '@laborhack/base-styles/lib/variables.scss';

.wrapper {
  margin-bottom: $margin-lg;

  &.disabled {
    .stepBodyWrapper {
      opacity: 0.5;
    }
    
  }
}

.stepBodyWrapper {
  display: grid;
  grid-template-columns: $grid-unit * 8 1fr;
  justify-items: start;
}

.stepBody {
  grid-column-start: 2;
}
