$primary-color: #009fe3;
$dark-blue: #003952;
$shadow-blue: #f5f9fa;

$activeSideNavBgColor: #05acf3;

$backgroundTextColor: #7c95a0;

@mixin flexbox() {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

@mixin flex($values) {
  -webkit-box-flex: $values;
  -moz-box-flex: $values;
  -webkit-flex: $values;
  -ms-flex: $values;
  flex: $values;
}

@mixin order($val) {
  -webkit-box-ordinal-group: $val;
  -moz-box-ordinal-group: $val;
  -ms-flex-order: $val;
  -webkit-order: $val;
  order: $val;
}

.o-layout__wrapper {
  @include flexbox();
  width: 100%;
  height: 100vh;
  font-family: 'Poppins', sans-serif;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    font-family: 'Poppins', sans-serif;
  }

  .o-mainNavigation__wrapper {
    @include flexbox();
    position: absolute;
    width: 100%;
    padding-top: 24px;
    justify-content: space-between;
    align-items: center;
    background: transparent;
    .a-mainNavigation__logo {
      @include flexbox();
      width: 10%;
      min-width: 150px;
      justify-content: center;
      h2 {
        margin: 0;
        font-size: 21px;
        line-height: 36px;
        font-weight: 700;
        color: #fff;
      }
    }

    .m-mainNavigation__actions {
      @include flexbox();
      padding-right: 100px;
      justify-content: space-between;
      align-items: center;
      flex-wrap: nowrap;
      width: 480px;

      span[role='img'] {
        cursor: pointer;
        font-size: 20px;
      }

      .a-mainNavigation__account {
        cursor: pointer;
        @include flexbox();
        align-items: center;
        flex-wrap: nowrap;
        svg {
          cursor: pointer;
        }
        p {
          margin: 0;
          font-weight: 600;
        }
      }

      .m-mainNavigation__avatarWrapper {
        @include flexbox();
        justify-content: center;
        align-items: center;
        margin: 0;
        width: 52px;
        height: 52px;
        background: linear-gradient(to right, red, orange);
        border-radius: 26px;
        .a-mainNavigation__avatar {
          width: 48px;
          height: 48px;
          background-color: black;
          border-radius: 24px;
        }
      }
    }
  }

  .o-layout__sideNavWrapper {
    @include flexbox();
    justify-content: center;
    padding-top: 24px;
    background-color: $primary-color;
    width: 280px;
  }

  .o-layout__mainWrapper {
    margin-top: 80px;
    flex: 1;
    padding: 0px 24px 24px 24px;
    background-color: $shadow-blue;
    overflow-y: auto;

    .o-layout__mainView {
      padding: 50px 0 0 0;
    }

    header {
      @include flexbox();
      width: 100%;
      justify-content: space-between;
      align-items: center;
      h3 {
        text-transform: capitalize;
        margin: 0;
        padding: 0;
        color: $dark-blue;
        font-size: 18px;
        font-weight: 600;
        line-height: 36px;
      }

      span {
        font-size: 18px;
      }
    }
  }

  .o-layout__accountWrapper {
    @include flexbox();
    justify-content: center;
    padding-top: 24px;
    width: 12%;
    min-width: 200px;
  }
}

.o-account__wrapper {
  @include flexbox();
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  .m-account__logout {
    @include flexbox();
    width: 80%;
    max-width: 100px;
    justify-content: space-around;
    align-items: center;
    cursor: pointer;

    p {
      margin: 0;
      padding: 0;
      font-size: 14px;
      font-weight: 600;
      line-height: 36px;
    }

    span {
      font-size: 14px;
    }
  }

  .m-account__userDetails {
    @include flexbox();
    width: 100%;
    flex-direction: column;
    align-items: center;
    color: $dark-blue;

    span {
      font-size: 48px;
      margin-bottom: 20px;
    }

    h3 {
      margin: 0;
      font-size: 18px;
    }

    h5 {
      margin: 0;
      color: $backgroundTextColor;
    }
  }

  .m-account__notifications {
    @include flexbox();
    width: 100%;
    justify-content: center;
    min-height: 300px;

    .a-account__notificationHeader {
      @include flexbox();
      width: 120px;
      justify-content: space-between;
      align-items: center;
      flex-wrap: nowrap;
      height: 36px;

      p {
        font-weight: 600;
        margin: 0;
        font-size: 14px;
      }

      span {
        font-size: 18px;
      }
    }

    .o-account__notificationList {
      overflow-y: auto;
    }
  }
}

.o-breadCrumb__wrapper {
  @include flexbox();
  align-items: center;
  flex-wrap: nowrap;
  h2 {
    font-size: 24px;
    line-height: 36px;
    font-weight: 700;
    color: $dark-blue;
    margin: 0 10px 0 0;
  }
  .m-breadCrumb__route {
    @include flexbox();
    align-items: center;
    flex-wrap: nowrap;
    p {
      font-weight: 600;
      color: $backgroundTextColor;
      margin: 0 5px 0 0;
      align-self: flex-end;
    }

    span {
      color: $backgroundTextColor;
      margin: 0 5px 0 0;
    }
  }
}

.o-sideNavigation__wrapper {
  @include flexbox();
  margin-top: 54px;
  width: 100%;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  overflow-y: auto;

  .o-sideNavigation__section {
    width: 100%;
    .o-sideNavigation__itemWrapper {
      cursor: pointer;
      position: relative;
      @include flexbox();
      width: 100%;
      justify-content: center;
      align-items: center;
      color: #8acfed;
      flex-wrap: wrap;

      :hover {
        color: #fff;
      }

      .o-sideNavigation__mainItemWrapper {
        cursor: pointer;
        position: relative;
        @include flexbox();
        width: 100%;
        height: 48px;
        justify-content: center;
        align-items: center;
        color: #8acfed;

        svg {
          path {
            stroke: #8acfed;
          }
        }

        &.-active {
          background: rgb(5, 172, 243);
          background: linear-gradient(
            90deg,
            rgba(5, 172, 243, 1) 0%,
            rgba(0, 159, 227, 1) 74%
          );
          color: #fff;

          svg {
            path {
              stroke: #fff;
            }
          }

          .a-sideNavigation__itemActiveBar {
            background-color: #fff;
          }
        }
      }

      .o-sideNavigation__subMenuItemWrapper {
        position: relative;
        @include flexbox();
        width: 100%;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        .o-sideNavigation__subMenuItemContainer {
          position: relative;
          @include flexbox();
          width: 90%;
          height: 32px;
          margin: 3px 0;
          justify-content: center;
          align-items: center;
          border-radius: 3px;
          color: #8acfed;

          &.-sub-active {
            background: #1db1f1;
            color: #fff;

            .m-sideNavigation__subMenuItem {
              span {
                svg {
                  fill: #fff;
                }
              }

              svg {
                color: #fff;
                path {
                  stroke: #fff;
                }
              }
            }
          }

          .m-sideNavigation__subMenuItem {
            @include flexbox();
            flex-wrap: nowrap;
            justify-content: flex-start;
            align-items: center;
            width: 70%;
            height: 32px;
            p {
              margin: 0 0 0 20px;
              padding: 0;
              font-size: 12px;
              font-weight: 500;
            }

            svg {
              width: 16px;
              height: 16px;

              path {
                color: #8acfed;
                stroke: #8acfed;
              }
            }

            span[role='img'] {
              font-size: 16px;
            }
          }
        }
      }

      .m-sideNavigation__item {
        @include flexbox();
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-items: center;
        width: 70%;
        p {
          margin: 0 0 0 20px;
          padding: 0;
          font-size: 14px;
          font-weight: 500;
        }

        svg {
          width: 20px;
          height: 20px;
        }

        span[role='img'] {
          font-size: 16px;
        }
      }

      .a-sideNavigation__itemActiveBar {
        position: absolute;
        right: 0;
        background-color: transparent;
        height: 36px;
        width: 5px;
      }

      &.-active {
        background: rgb(5, 172, 243);
        background: linear-gradient(
          90deg,
          rgba(5, 172, 243, 1) 0%,
          rgba(0, 159, 227, 1) 74%
        );
        color: #fff;

        .a-sideNavigation__itemActiveBar {
          background-color: #fff;
        }
      }
    }
  }
}

.m-mainNavigation__popover {
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  .m-mainNavigation__button {
    cursor: pointer;
    @include flexbox();
    justify-content: center;
    align-items: center;
    width: 100px;
    flex-wrap: nowrap;
    color: $backgroundTextColor;
    p {
      margin: 0;
    }

    :hover {
      color: $dark-blue;
    }
  }
}

$backgroundColor: #d8eaf1;

.o-order__tableControls {
  input {
    height: 42px !important;
    background-color: $backgroundColor !important;
    border: none !important;
    font-family: 'Poppins' !important;
    font-size: 12px !important;
    font-weight: 500 !important;
    &::placeholder {
      color: #507a8c !important;
    }
  }

  .m-order__dropdown {
    font-family: 'Poppins';
    background-color: $primary-color;
    color: #fff;
    font-size: 12px !important;
    font-weight: 400 !important;
    &.-secondary {
      background-color: $dark-blue;
      &:hover {
        background-color: #000000 !important;
        color: #fff !important;
      }
      &:active {
        background-color: #000000;
        color: #fff;
      }
      &:focus {
        background-color: $dark-blue;
        color: #fff;
      }
    }
    &:hover {
      background-color: $primary-color !important;
      color: #fff !important;
    }
    &:active {
      background-color: $primary-color;
      color: #fff;
    }
    &:focus {
      background-color: $primary-color;
      color: #fff;
    }
  }
}

.o-table__wrapper {
  thead {
    tr {
      th {
        font-size: 12px;
        color: #507a8c !important;
        font-weight: 600;
      }
    }
  }

  tbody {
    tr {
      &:hover {
        cursor: pointer;
        box-shadow: 0px 4px 18px 5px rgba(158, 203, 214, 0.5);
      }
      td {
        color: $dark-blue !important;
        font-weight: 600;
        font-size: 12px;
        .a-table__progress {
          margin: 0 !important;
          font-family: 'Poppins' !important;
          font-weight: 300 !important;
        }
      }
    }
  }

  .m-table__actions {
    width: 100%;
    @include flexbox();
    justify-content: center;
    margin: 16px 0 0 0;
    .a-table__actionButton {
      border: none;
      border-radius: 3px;
      background-color: $dark-blue;
      color: #fff;
      font-weight: 600;
      padding: 5px 20px;
      cursor: pointer;

      &:disabled {
        cursor: not-allowed;
        background-color: $backgroundTextColor;
      }
    }
  }
}

.m-trail__wrapper {
  @include flexbox();
  align-items: center;
  margin: 0;

  h2,
  h3 {
    color: $dark-blue;
    font-size: 18px;
    margin: 0 !important;
  }

  h3 {
    margin-right: 10px;
    font-weight: 600;
    color: $backgroundTextColor;
  }

  i {
    color: #b7cad2;
    line-height: 1em;
    margin: 0 6px 0 6px;
  }
}

.m-badge__wrapper {
  width: fit-content;
  border-radius: 3px;

  p {
    margin: 0;
    padding: 5px 12px;
    font-weight: 600;
  }
}

.o-tabToggle__wrapper {
  @include flexbox();
  justify-content: space-between;
  align-items: center;
  width: 100%;

  .a-tabToggle__divider {
    p {
      font-size: 18px;
      font-weight: 600;
      font-style: italic;
      color: $dark-blue;
    }
  }

  .o-tabToggle__optionContainer {
    cursor: pointer;
    .m-tabToggle__optionLabel {
      @include flexbox();
      height: 48px;
      align-items: center;
      border-bottom: 2px solid #bcced6;

      &.-selected {
        border-bottom-color: #56bd66;

        p {
          color: $dark-blue;
        }

        i {
          color: #56bd66;
        }
      }

      p {
        margin: 0 10px 0 0;
        font-weight: 600;
        color: #bcced6;
      }

      i {
        color: #bcced6;
        margin: 0 20px 0 0;
        font-size: 14px;
        line-height: 14px;
      }
    }
  }
}

.m-customInput__container {
  @include flexbox();
  flex-wrap: wrap;
  margin: 20px 0 0 0;
  .a-customInput__label {
    width: 100%;
    font-size: 13px;
    font-weight: 600;
    color: $backgroundTextColor;
    .a-customInput__labelRequired {
      color: red;
    }
  }

  .a-customInput__input {
    height: 36px;
    padding: 0 0 0 10px;
    border-radius: 3px;
    border: 1px solid $backgroundTextColor;
    font-weight: 600;
    color: $dark-blue;
    width: 100%;

    &.-textArea {
      padding: 10px;
      height: 108px;
    }

    &:focus {
      border: 1px;
    }

    &:disabled {
      border-color: #d4d4d4;
      color: #d4d4d4;
    }
  }
}
